import LFCAutocomplete, { multiSelectData } from '_components/inputs/LFCAutocomplete';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatePicker from '_components/inputs/LFCDatePicker';
import LFCTextField from '_components/inputs/LFCTextField';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import { COLOR_NG, COLOR_OK } from '_logics/LFCConst';
import { getLFCData, handleInputChange } from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import axios, { CancelTokenSource } from 'axios';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import {
    Box, Button, Card, Chip, Divider, Grid, Slider, Stack, ToggleButton, ToggleButtonGroup, Tooltip,
    Typography
} from '@mui/material';

import ImageResults, { SelectedMapIconProps } from './_components/ImageResults';
import { DataItem, LFCChartsGantt } from './_components/LFCChartsGantt';

const RESEARCH_INTERVAL = 20000; //再検索間隔
const SERIAL_FOCUS_INTERVAL = 5000; //自動シリアルフォーカス間隔

type SearchCondition = {
  serial: string;
  tdate: string;
  work: [];
  deviceid: [];
};

enum SearchMode {
  auto = 'auto', //最新モード
  serial = 'serial', //シリアルモード
  date = 'date' //日付モード
}

enum ExKeyMode {
  work = 'work', //ワーク選択モード
  map = 'map' //部位選択モード
}

type ResData = {
  uuid: string;
  work: string;
  serial: string;
  start_time: string;
  end_time: string;
  total_judge: number;
  deviceid: string;
};

/**
 * ResultWorkImagePage
 * @returns
 */
const ResultWorkImagePage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(false);
  const [screenLock, setScreenLock] = useState(false);
  const cancelSource = useRef<CancelTokenSource | null>(null);

  const initialSearchValue: SearchCondition = {
    serial: '',
    tdate: dayjs().format('YYYY-MM-DD'),
    work: [],
    deviceid: []
  }; // 検索条件初期値
  const [searchValue, setSearchValue] = useState<SearchCondition>(initialSearchValue); //検索条件
  const [resDatas, setResDatas] = useState<ResData[]>([]); //検索結果データ
  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  const [deviceid, setDeviceid] = useState<{label: string}[]>([]);

  const containerRef = useRef<HTMLDivElement>(null);
  const [imagePath, setImagePath] = useState<string>('');
  const [imageRootPath, setImageRootPath] = useState<string>('');
  const [imgWidth, setImgWidth] = useState(900);

  const textSerialRef = useRef<HTMLInputElement>(null);
  const [autoSerialFocus, setAutoSerialFocus] = useState(false);
  const serialFocusTimer = useRef<NodeJS.Timeout | null>(null);
  const [autoReSearch, setAutoReSearch] = useState(false);
  const reSearchTimer = useRef<NodeJS.Timeout | null>(null);
  const [searchMode, setSearchMode] = useState<SearchMode>(SearchMode.serial);
  const [ganttZoom, setGanttZoom] = useState<number[]>([0, 100]);
  const [selectedData, setSelectedData] = useState<ResData>({
    uuid: '',
    work: '',
    serial: '',
    start_time: '',
    end_time: '',
    total_judge: 0,
    deviceid: ''
  }); //選択中のデータ
  const [currentIndex, setCurrentIndex] = useState(0); //選択中のデータのインデックス

  const [checkResultsJob, setCheckResultsJob] = useState([]); //検査結果(ジョブ毎)
  const [mapBases, setWorkBases] = useState([]); //ワーク画像
  const [mapPointResults, setMapPointResults] = useState([]); //位置情報
  const [mapImages, setMapImages] = useState([]); //Map画像情報

  const [selectedMap, setSelectedMap] = useState<string | null>(null);

  const [workImageGridBreakPoints, setWorkImageGridBreakPoints] = useState(6);

  const exKeyMode = useRef<ExKeyMode>(ExKeyMode.work);
  const prevButtonRef = useRef<HTMLButtonElement>(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const prevMapButtonRef = useRef<HTMLButtonElement>(null);
  const nextMapButtonRef = useRef<HTMLButtonElement>(null);
  const [selectedMapIcon, setSelectedMapIcon] = useState<SelectedMapIconProps | null>(null);

  /**
   * ウィンドウリサイズ時の処理
   */
  const updateWidth = () => {
    if (containerRef.current) {
      setImgWidth(containerRef.current.offsetWidth - 5);
    }
  };

  /**
   * 特殊キー処理
   * @param event
   */
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      //選択モード＝ワーク選択モードに戻す
      event.preventDefault();
      exKeyMode.current = ExKeyMode.work;
    } else if (event.ctrlKey && event.code === 'Space') {
      //選択モード切替
      event.preventDefault();
      exKeyMode.current = exKeyMode.current === ExKeyMode.work ? ExKeyMode.map : ExKeyMode.work;
    } else if (event.ctrlKey && event.key === 'ArrowLeft') {
      event.preventDefault();
      if (exKeyMode.current === ExKeyMode.work) {
        //１つ前のワークへ
        prevButtonRef.current?.click();
      } else {
        //１つ前の部位へ
        prevMapButtonRef.current?.click();
      }
    } else if (event.ctrlKey && event.key === 'ArrowRight') {
      event.preventDefault();
      if (exKeyMode.current === ExKeyMode.work) {
        //１つ後のワークへ
        nextButtonRef.current?.click();
      } else {
        //１つ後の部位へ
        nextMapButtonRef.current?.click();
      }
    }
  };

  /**
   * 検索モード変更イベント
   */
  const modeChange = (event: React.MouseEvent<HTMLElement>, newMode: SearchMode) => {
    if (newMode == null) {
      //2度押しでOFFにしない対応
      return;
    }

    setSearchMode(newMode);
  };

  /**
   * 検索処理
   */
  const doSearch = (onEnter: boolean) => {
    if (cancelSource.current) cancelSource.current.cancel('req cancel');

    // input check
    if (!formRef.current?.reportValidity()) {
      return;
    }

    let tSsqlid = 0;
    let tParams = {};
    let message = '';

    switch (searchMode) {
      case SearchMode.auto:
        //自動更新モード
        tSsqlid = 9005;
        tParams = {
          serial: null,
          work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
          deviceid: !isEmpty(searchValue.deviceid)
            ? '{' + searchValue.deviceid.join(',') + '}'
            : null
        };
        message = '最新データ';
        break;
      case SearchMode.serial:
        //シリアル検索モード
        tSsqlid = 9005;
        tParams = {
          serial: searchValue.serial,
          work: null,
          deviceid: null
        };
        message = searchValue.serial;
        break;
      default:
        //日付検索モード
        tSsqlid = 9000;
        tParams = {
          tdate: searchValue.tdate,
          work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
          deviceid: !isEmpty(searchValue.deviceid)
            ? '{' + searchValue.deviceid.join(',') + '}'
            : null
        };
        message = searchValue.tdate;
        break;
    }

    setScreenLock(true);
    cancelSource.current = axios.CancelToken.source();
    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: tSsqlid,
        parameters: tParams,
        cancelToken: cancelSource.current.token,
        name: message,
        t
      }).then(ds => {
        setResDatas(ds);
      })
    ]).finally(() => {
      setScreenLock(false);

      //シリアル検索モードかつエンターキーで検索した場合＝バーコード入力を想定
      if (searchMode === SearchMode.serial && onEnter) {
        //連続実行を可能にするため入力値をクリア
        setSearchValue({...searchValue, serial: ''});
        //フォーカスをシリアルに当てるためのタイマー設定
        setAutoSerialFocus(true);
      }
    });
  };

  /**
   * AutocompletenのonChange
   */
  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  /**
   * Nextボタンクリック
   */
  const handleNext = () => {
    const newIndex = (currentIndex + 1) % resDatas.length;
    setSelectedData(resDatas[newIndex]);
    setCurrentIndex(newIndex);
  };

  /**
   * Prevボタンクリック
   */
  const handlePrev = () => {
    const newIndex = (currentIndex - 1 + resDatas.length) % resDatas.length;
    setSelectedData(resDatas[newIndex]);
    setCurrentIndex(newIndex);
  };

  /**
   * MapNextボタンクリック
   */
  const handleMapNext = () => {
    //１つ後の部位へ
    if (!selectedMapIcon) return;

    const nowMapIdx = mapPointResults.findIndex(
      (mp: any) => mp['image_no'] === selectedMapIcon.imageNo && mp['map'] === selectedMapIcon.map
    );

    if (nowMapIdx + 1 >= mapPointResults.length) return;

    setSelectedMapIcon({
      imageNo: mapPointResults[nowMapIdx + 1]['image_no'],
      map: mapPointResults[nowMapIdx + 1]['map']
    });
  };

  /**
   * MapPrevボタンクリック
   */
  const handleMapPrev = () => {
    //１つ前の部位へ
    if (!selectedMapIcon) return;

    const nowMapIdx = mapPointResults.findIndex(
      (mp: any) => mp['image_no'] === selectedMapIcon.imageNo && mp['map'] === selectedMapIcon.map
    );

    if (nowMapIdx - 1 < 0) return;

    setSelectedMapIcon({
      imageNo: mapPointResults[nowMapIdx - 1]['image_no'],
      map: mapPointResults[nowMapIdx - 1]['map']
    });
  };

  /**
   * 日付ガントクリック時
   */
  const handleGanttClick = (uuid: string) => {
    // クリックしたデータを選択済にする
    if (!uuid) return;

    const fIdx = resDatas.findIndex(r => r['uuid'] === uuid);
    if (fIdx < 0) return;

    setSelectedData(resDatas[fIdx]);
    setCurrentIndex(fIdx);
  };

  /***
   * 画像拡大縮小変更
   */
  const onChangeImageGridBreakPoints = (event: Event, newValue: number | number[]) => {
    setWorkImageGridBreakPoints(newValue as number);
  };

  /**
   * 初期処理
   */
  useEffect(() => {
    // 画像サイズの初期の幅を設定
    updateWidth();
    // ウィンドウのリサイズイベントにリスナーを追加
    window.addEventListener('resize', updateWidth);
    window.addEventListener('keydown', handleKeyDown);

    setStartProcess(true);
    cancelSource.current = axios.CancelToken.source();
    Promise.allSettled([
      // 機種情報取得
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 40001,
        parameters: {},
        cancelToken: cancelSource.current.token,
        t
      }).then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['work']};
        });
        setWork(tmp);
      }),
      // 検査装置情報取得
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 40002,
        parameters: {},
        cancelToken: cancelSource.current.token,
        t
      }).then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['deviceid']};
        });
        setDeviceid(tmp);
      }),
      // 画像パス取得
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 131,
        parameters: {},
        cancelToken: cancelSource.current.token,
        t
      }).then(ds => {
        if (ds.length === 0) return;
        const path = ds[0]['svalue'];

        setImagePath(path);

        // ドメインまでのパスに変換
        const match = path.match(/^(https?:\/\/[^/]+\/)/);
        const rootPath = match ? match[1] : '';

        setImageRootPath(rootPath);
      })
    ]).finally(() => {
      setStartProcess(false);
    });

    return () => {
      window.removeEventListener('resize', updateWidth);
      window.removeEventListener('keydown', handleKeyDown);
      if (cancelSource.current) cancelSource.current.cancel('req cancel');
    };
  }, []);

  //検索値の初期値をオートコンプリートに反映
  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work, deviceid]);

  /**
   * 検索結果変更時の処理
   */
  useEffect(() => {
    if (resDatas.length > 0) {
      switch (searchMode) {
        case SearchMode.auto:
          //最新モード
          //最新データを選択済にする
          setSelectedData(resDatas[resDatas.length - 1]);
          setCurrentIndex(resDatas.length - 1);
          setGanttZoom([90, 100]);
          break;
        case SearchMode.serial:
          //シリアルモード
          //指定シリアルを選択済にする
          const fidx = resDatas.findIndex(r => r['serial'] === searchValue.serial) || 0;
          setSelectedData(resDatas[fidx]);
          setCurrentIndex(fidx);
          setGanttZoom([0, 100]);
          break;
        default:
          //日付モード
          //先頭データを選択済にする
          setSelectedData(resDatas[0]);
          setCurrentIndex(0);
          setGanttZoom([0, 100]);
          break;
      }
    } else {
      setCurrentIndex(0);
    }
  }, [resDatas]);

  /**
   * 検査データ選択時の処理
   */
  useEffect(() => {
    // 検査結果、ワーク画像、位置情報取得
    if (selectedData.uuid === '') {
      return;
    }

    if (cancelSource.current) cancelSource.current.cancel('req cancel');

    // setSelectedMap('');

    setStartProcess(true);
    cancelSource.current = axios.CancelToken.source();
    Promise.allSettled([
      //部位毎の検査結果取得
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 9001,
        parameters: {
          uuid: selectedData.uuid
        },
        cancelToken: cancelSource.current.token,
        t
      }).then(datas => ({checkResultsMap: datas})),
      //検査結果Pointヘッダ取得
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 9002,
        parameters: {
          work: selectedData.work
        },
        cancelToken: cancelSource.current.token,
        t
      }).then(datas => ({workBases: datas})),
      //検査結果Point詳細取得
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 9003,
        parameters: {
          work: selectedData.work
        },
        cancelToken: cancelSource.current.token,
        t
      }).then(datas => ({mapPoints: datas})),
      //詳細表示用Map画像データ取得
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 9004,
        parameters: {
          uuid: selectedData.uuid
        },
        cancelToken: cancelSource.current.token,
        t
      }).then(datas => ({mapImages: datas})),
      //詳細表示用ジョブ毎の検査結果取得
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 9006,
        parameters: {
          uuid: selectedData.uuid
        },
        cancelToken: cancelSource.current.token,
        t
      }).then(datas => ({checkResultsJob: datas}))
    ])
      .then(res => {
        if (res.find((r: any) => String(r['reason']).match(/Error:/))) {
          //エラー（キャンセル含む場合は何もしない）
          return;
        }

        const resSum = res.reduce((acc: Record<string, any>, curr: Record<string, any>) => {
          return {...acc, ...curr['value']};
        }, {});

        setWorkBases(resSum['workBases']);

        let filteredMapPoints = resSum['mapPoints']
          .filter((mapPoint: any) =>
            resSum['checkResultsMap'].some(
              (checkResult: any) => checkResult['map'] === mapPoint['map']
            )
          )
          .map((mapPoint: any) => {
            const checkResult = resSum['checkResultsMap'].find(
              (checkResult: any) => checkResult['map'] === mapPoint['map']
            );
            return {
              image_no: mapPoint['image_no'],
              map: mapPoint['map'],
              relative_x: mapPoint['relative_x'],
              relative_y: mapPoint['relative_y'],
              judge: checkResult['judge']
            };
          });

        resSum['checkResultsMap']
          .filter(
            (checkResult: any) =>
              !resSum['mapPoints'].some((mapPoint: any) => mapPoint['map'] === checkResult['map'])
          )
          .forEach((checkResult: any) =>
            filteredMapPoints.push({
              image_no: 99,
              map: checkResult['map'],
              relative_x: 0,
              relative_y: 0,
              judge: checkResult['judge']
            })
          );
        setMapPointResults(filteredMapPoints);

        setMapImages(resSum['mapImages']);
        setCheckResultsJob(resSum['checkResultsJob']);

        updateWidth();
      })
      .finally(() => {
        setStartProcess(false);
      });
  }, [selectedData]);

  useEffect(() => {
    // 初期選択アイコン設定

    if (selectedMapIcon !== null) {
      //MAP選択済で該当ポイントがあればそのまま
      if (
        mapPointResults.findIndex(
          (mp: any) =>
            mp['image_no'] === selectedMapIcon.imageNo && mp['map'] === selectedMapIcon.map
        ) > -1
      ) {
        return;
      }
    }

    //該当ポイント無ければポイントの先頭を選択
    const firstPoint =
      mapPointResults.length > 0
        ? {
            imageNo: mapPointResults[0]['image_no'],
            map: mapPointResults[0]['map']
          }
        : null;

    if (firstPoint !== null) {
      setSelectedMapIcon(firstPoint);
      return;
    }
  }, [mapPointResults]);

  /***
   * 画面ロック状態切替
   */
  useEffect(() => {
    setStartProcess(screenLock);
  }, [screenLock]);

  /***
   * ワーク画像表示比率変更
   */
  useEffect(() => {
    updateWidth();
  }, [workImageGridBreakPoints]);

  /***
   * 自動更新ON/OFF切替
   */
  useEffect(() => {
    if (autoReSearch) {
      //自動更新ON

      //一旦キャンセル
      if (reSearchTimer.current) {
        clearInterval(reSearchTimer.current);
        reSearchTimer.current = null;
      }

      //自動更新セット
      doSearch(false);
      reSearchTimer.current = setInterval(() => {
        console.debug('reSearchTimer');
        doSearch(false);
      }, RESEARCH_INTERVAL);

      enqueueSnackbar('自動更新を開始しました。', {
        variant: 'info'
      });
    } else {
      //自動更新OFF
      if (reSearchTimer.current) {
        clearInterval(reSearchTimer.current);
        reSearchTimer.current = null;

        enqueueSnackbar('自動更新を停止しました。', {
          variant: 'info'
        });
      }
    }

    return () => {
      if (reSearchTimer.current) {
        clearInterval(reSearchTimer.current);
        reSearchTimer.current = null;
      }
    };
  }, [autoReSearch]);

  /***
   * 自動シリアルフォーカスON/OFF切替
   */
  useEffect(() => {
    if (autoSerialFocus) {
      //ON

      //一旦キャンセル
      if (serialFocusTimer.current) {
        clearInterval(serialFocusTimer.current);
        serialFocusTimer.current = null;
      }

      //セット
      serialFocusTimer.current = setInterval(() => {
        console.debug('serialFocusTimer');
        textSerialRef.current?.focus();
      }, SERIAL_FOCUS_INTERVAL);
    } else {
      //OFF
      if (serialFocusTimer.current) {
        clearInterval(serialFocusTimer.current);
        serialFocusTimer.current = null;
      }
    }

    return () => {
      if (serialFocusTimer.current) {
        clearInterval(serialFocusTimer.current);
        serialFocusTimer.current = null;
      }
    };
  }, [autoSerialFocus]);

  /***
   * 検索モード切替
   */
  useEffect(() => {
    //自動更新モード以外になったら、自動再検索をOFFにする
    if (searchMode !== SearchMode.auto && autoReSearch) {
      setAutoReSearch(false);
    }

    //シリアルモード中だけシリアルへの定期フォーカスONにする
    if (searchMode === SearchMode.serial) {
      setAutoSerialFocus(true);
    } else {
      setAutoSerialFocus(false);
    }
  }, [searchMode]);

  return (
    <GenericTemplate title={t('menu.page_name.検査結果（ワーク画像）')}>
      <ProgressBar startProcess={startProcess} screenLock={screenLock} />
      <form ref={formRef}>
        <LFCFormRowGroup>
          <ToggleButtonGroup
            size="small"
            value={searchMode}
            exclusive
            onChange={modeChange}
            aria-label="Platform"
          >
            <ToggleButton value="date">日付検索</ToggleButton>
            <ToggleButton value="serial">シリアル検索</ToggleButton>
            <ToggleButton value={SearchMode.auto}>自動更新</ToggleButton>
          </ToggleButtonGroup>
          <Stack direction="row" spacing={1}>
            {searchMode === SearchMode.serial ? (
              <LFCTextField
                name="serial"
                label={t('シリアル')}
                value={searchValue.serial}
                onChange={event => {
                  handleInputChange(event, searchValue, setSearchValue);
                }}
                onKeyDown={event => {
                  if (!event) return;

                  if (event.key === 'Enter') {
                    doSearch(true);
                    event.preventDefault();
                  }
                }}
                sx={{width: '300px'}}
                autoFocus
                ref={textSerialRef}
                required
              />
            ) : null}
            {searchMode === SearchMode.date ? (
              <LFCDatePicker
                name="tdate"
                label={t('日付')}
                value={searchValue.tdate}
                onChange={event => {
                  handleInputChange(event, searchValue, setSearchValue);
                }}
                required
              />
            ) : null}
            {searchMode === SearchMode.auto || searchMode === SearchMode.date ? (
              <>
                <LFCAutocomplete
                  name={t('work')}
                  label={t('機種')}
                  id={'work'}
                  size="small"
                  value={multiSelectData(work, searchValue.work)}
                  onChange={autoOnChange}
                  onReset={autoCompleteReset}
                  doneReset={setAutoCompleteReset}
                  multiple={true}
                  selectItem={work}
                  disabled={autoReSearch}
                />
                <LFCAutocomplete
                  name={'deviceid'}
                  label={t('検査装置')}
                  id={'deviceid'}
                  size="small" // small/medium
                  value={multiSelectData(deviceid, searchValue.deviceid)}
                  onChange={autoOnChange}
                  onReset={autoCompleteReset}
                  doneReset={setAutoCompleteReset}
                  multiple={true}
                  selectItem={deviceid}
                  disabled={autoReSearch}
                />
              </>
            ) : null}
            {searchMode === SearchMode.auto ? (
              <Button
                variant="contained"
                color={!autoReSearch ? 'primary' : 'secondary'}
                startIcon={!autoReSearch ? <PlayArrowIcon /> : <StopIcon />}
                onClick={() => setAutoReSearch(!autoReSearch)}
              >
                {!autoReSearch ? t('開始') : t('停止')}
              </Button>
            ) : null}
            {searchMode === SearchMode.serial || searchMode === SearchMode.date ? (
              <LFCButton color="primary" onClick={() => doSearch(false)}>
                {t('検索')}
              </LFCButton>
            ) : null}
          </Stack>
        </LFCFormRowGroup>
      </form>
      <Divider style={{margin: '1em 0'}} />
      {resDatas.length > 0 ? (
        <Grid container alignItems="center" spacing={0}>
          <Grid item xs={1} container justifyContent="center" alignItems="center">
            <Button ref={prevButtonRef} onClick={handlePrev} variant="contained">
              <ArrowBackIcon />
            </Button>
          </Grid>
          <Grid item xs={10} container justifyContent="center" alignItems="center">
            <LFCChartsGantt
              ds={resDatas as DataItem[]}
              selectedIndex={currentIndex}
              zoom={ganttZoom}
              onClick={handleGanttClick}
            />
          </Grid>
          <Grid item xs={1} container justifyContent="center" alignItems="center">
            <Button ref={nextButtonRef} onClick={handleNext} variant="contained">
              <ArrowForwardIcon />
            </Button>
          </Grid>
          <Grid item xs={12} sm={9} md={10}>
            <Stack direction="row" flexWrap={'wrap'} mt={1}>
              <Stack direction="row" alignItems="center" mr={1} mb={1}>
                <Chip size="small" label="総合判定" />
                <Typography
                  ml={1}
                  px={2}
                  variant="h4"
                  noWrap
                  sx={{
                    backgroundColor: selectedData.total_judge === 0 ? COLOR_OK : COLOR_NG,
                    animation:
                      selectedData.total_judge === 0
                        ? ''
                        : 'map-pulse 1s infinite, map-flash 1s infinite'
                  }}
                >
                  {selectedData.total_judge === 0 ? 'OK' : 'NG'}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" mr={1} mb={1}>
                <Chip size="small" label="時間" />
                <Tooltip title={dayjs(selectedData.start_time).format('llll:ss')} arrow>
                  <Typography ml={1} variant="h6" noWrap>
                    {dayjs(selectedData.start_time).format('llll:ss')}
                  </Typography>
                </Tooltip>
              </Stack>
              <Stack direction="row" alignItems="center" mr={1} mb={1}>
                <Chip size="small" label="シリアル" />
                <Tooltip title={selectedData.serial} arrow>
                  <Typography ml={1} variant="h6" noWrap>
                    {selectedData.serial}
                  </Typography>
                </Tooltip>
              </Stack>
              <Stack direction="row" alignItems="center" mr={1} mb={1}>
                <Chip size="small" label="機種" />
                <Tooltip title={selectedData.work} arrow>
                  <Typography ml={1} variant="h6" noWrap>
                    {selectedData.work}
                  </Typography>
                </Tooltip>
              </Stack>
              <Stack direction="row" alignItems="center" mr={1} mb={1}>
                <Chip size="small" label="検査装置" />
                <Tooltip title={selectedData.deviceid} arrow>
                  <Typography ml={1} variant="h6" noWrap>
                    {selectedData.deviceid}
                  </Typography>
                </Tooltip>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={3} md={2} container justifyContent="end" alignItems="start">
            <Stack direction="row" flexWrap={'wrap'}>
              <Stack direction="row" alignItems="center" mr={1} mb={1}>
                <Chip size="small" label="拡大縮小" variant="outlined" />
                <Slider
                  aria-label="zoom"
                  value={workImageGridBreakPoints}
                  onChange={onChangeImageGridBreakPoints}
                  min={2}
                  max={10}
                  sx={{width: '75px', mx: 2}}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={workImageGridBreakPoints} ref={containerRef}>
              <ImageResults
                imgWidth={imgWidth}
                imageRootPath={imageRootPath}
                workBases={mapBases}
                mapPointResults={mapPointResults}
                selected={selectedMapIcon}
                onSelected={(imageNo: number, map: string) => {
                  setSelectedMapIcon({imageNo: imageNo, map: map});
                  setSelectedMap(map);
                }}
              ></ImageResults>
            </Grid>
            <Grid item xs={12 - workImageGridBreakPoints}>
              {selectedMap != null
                ? (() => {
                    const mapJudge = mapPointResults.find(r => r['map'] === selectedMap)?.['judge'];
                    //データが無い場合は何も表示しない(基本的にあり得ない)
                    if (mapJudge === undefined) return null;

                    return (
                      <Card sx={{m: 1, p: 1}}>
                        <Grid container justifyContent="start" mb={2} item xs={12}>
                          <Button
                            variant="contained"
                            ref={prevMapButtonRef}
                            onClick={handleMapPrev}
                          >
                            <ArrowBackIcon />
                          </Button>
                          <Box mr={2}></Box>
                          <Button
                            variant="contained"
                            ref={nextMapButtonRef}
                            onClick={handleMapNext}
                          >
                            <ArrowForwardIcon />
                          </Button>
                        </Grid>
                        <Stack direction="row" flexWrap={'wrap'}>
                          <Stack direction="row" alignItems="center" mr={1} mb={2}>
                            <Chip size="small" label="部位判定" />
                            <Typography ml={1} variant="h6" noWrap>
                              {selectedMap || '-'}
                            </Typography>
                            <Typography
                              ml={1}
                              px={2}
                              variant="h6"
                              noWrap
                              sx={{
                                backgroundColor: mapJudge === 0 ? COLOR_OK : COLOR_NG
                              }}
                            >
                              {mapJudge === 0 ? 'OK' : 'NG'}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack direction="row" flexWrap={'wrap'}>
                          {checkResultsJob
                            .filter((r: any) => r['map'] === selectedMap)
                            .map((r: any, index: number) => (
                              <Stack
                                direction="row"
                                alignItems="center"
                                key={`stack-${r['job_type_name']}-${index}`}
                                mr={1}
                                mb={1}
                              >
                                <Chip size="small" label="ジョブ判定" />
                                <Typography ml={1} variant="body1" noWrap>
                                  {r['job_type_name']}
                                </Typography>
                                <Typography
                                  ml={1}
                                  px={1}
                                  variant="body1"
                                  noWrap
                                  sx={{
                                    backgroundColor: r['judge'] === 0 ? COLOR_OK : COLOR_NG
                                  }}
                                >
                                  {`${r['judge'] === 0 ? 'OK' : 'NG'}`}
                                </Typography>
                              </Stack>
                            ))}
                        </Stack>
                        <Stack alignItems="start" spacing={2}>
                          {mapImages
                            .filter((r: any) => r['map'] === selectedMap)
                            .map((r: any) => (
                              <Box>
                                <Typography>{dayjs(r['lq_time']).format('llll:ss')}</Typography>
                                <img
                                  src={`${imagePath}${r['img_filename']}`}
                                  width="100%"
                                  alt=""
                                  title={r['lq_time']}
                                />
                              </Box>
                            ))}
                        </Stack>
                      </Card>
                    );
                  })()
                : null}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </GenericTemplate>
  );
};

export default ResultWorkImagePage;
