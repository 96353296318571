import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCButton from '_components/inputs/LFCButton';
import ProgressBar from '_components/ProgressBar';
import {getLFCData, setLFCDataProc} from '_logics/LFCUtil';
import axios, {CancelTokenSource} from 'axios';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography
} from '@mui/material';

/**
 * 引数
 */
interface Props {
  open: boolean;
  onClose: (isRefresh: boolean) => void;
  work: string;
  imageNo: number;
}

/**
 * 機種画像登録ダイアログ
 * @param props
 * @returns
 */
const WorkImageDialog = (props: Props) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const DefInputValue = {
    work: '',
    imageNo: 0,
    imageData: '',
    aspectRatio: 1
  };
  const formRef = useRef<HTMLFormElement>(null!);
  const [inputValue, setInputValue] = useState(DefInputValue);
  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  const cancelSource = useRef<CancelTokenSource | null>(null);

  /**
   * 保存処理
   * @returns
   */
  const onSave = () => {
    if (cancelSource.current) cancelSource.current.cancel('req cancel');

    // 入力チェック
    if (!formRef.current?.reportValidity()) {
      return;
    }

    if (inputValue.work === '') {
      enqueueSnackbar(`機種を選択してください`, {variant: 'error'});
      return;
    }
    if (inputValue.imageData === '') {
      enqueueSnackbar(`画像ファイルを選択してください`, {variant: 'error'});
      return;
    }

    setStartProcess(true);
    cancelSource.current = axios.CancelToken.source();
    Promise.allSettled([
      setLFCDataProc({
        snack: enqueueSnackbar,
        name: '登録処理',
        invoke_name: 'CreateWorkImage',
        parameters: {
          work: inputValue.work,
          imageNo: inputValue.imageNo,
          imageData: inputValue.imageData,
          aspectRatio: inputValue.aspectRatio
        },
        cancelToken: cancelSource.current.token,
        t
      })
        .then((datas: []) => {
          onClose(true);
        })
        .catch(err => {
          console.error(err);
          enqueueSnackbar(`登録に失敗しました`, {variant: 'error'});
        })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  /**
   * Close処理
   * @returns
   */
  const onClose = (isRefresh: boolean) => {
    props.onClose(isRefresh);
  };

  /**
   * 画像指定イベント
   */
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        setInputValue({...inputValue, imageData: e.target?.result as string});
      };
      reader.readAsDataURL(file);
    }
  };

  /**
   * 画像ロード時、aspectRatioを計算
   */
  const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const img = event.currentTarget;
    const aspectR = parseFloat((img.naturalHeight / img.naturalWidth).toFixed(8));
    setInputValue({...inputValue, aspectRatio: aspectR});
  };

  /**
   * 外部 open変更検知
   */
  useEffect(() => {
    if (!props.open) {
      // クリア処理
      setAutoCompleteReset(true);
      setInputValue(DefInputValue);
      return;
    }

    inputValue.work = props.work;
    inputValue.imageNo = props.imageNo;

    setWork([]);

    cancelSource.current = axios.CancelToken.source();
    // 機種データ取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 8003,
      parameters: {},
      cancelToken: cancelSource.current.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['work']};
      });
      setWork(tmp);
    });
  }, [props.open]);

  useEffect(() => {
    return () => {
      if (cancelSource.current) cancelSource.current.cancel('req cancel');
    };
  }, []);

  return (
    <Dialog open={props.open} onClose={() => onClose(false)} maxWidth={'xs'}>
      <ProgressBar startProcess={startProcess} screenLock />
      <LFCDialogTitle onClose={() => onClose(false)}>機種画像登録</LFCDialogTitle>
      <DialogContent dividers>
        <form ref={formRef}>
          <Grid container spacing={1} mb={2}>
            <Grid item xs={12}>
              {/* <LFCSelectFormWorkEx
              name={'work'}
              value={inputValue.work}
              onChange={event => handleInputChange2(event, inputValue, setInputValue)}
            ></LFCSelectFormWorkEx> */}
              {props.work === '' ? (
                <LFCAutocomplete
                  id={'work'}
                  name={'work'}
                  label={t('機種')}
                  size="small" // small/medium
                  value={multiSelectData(work, inputValue.work)}
                  onChange={(relayDatas: any) => {
                    if (relayDatas === '') {
                      setInputValue({...inputValue, work: ''});
                      return;
                    }
                    setInputValue({...inputValue, [relayDatas.name]: relayDatas.data});
                  }}
                  onReset={false}
                  doneReset={false}
                  multiple={false}
                  selectItem={work}
                  required
                />
              ) : (
                <Box>
                  <Chip size="small" label="機種" />
                  <Typography mt={0.5} ml={1}>
                    {props.work}
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Chip size="small" label="画像No" />
                <Typography mt={0.5} ml={1}>
                  {props.imageNo}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Chip size="small" label="画像" />
                <Box mt={0.5} ml={1} display="flex" justifyContent="start">
                  {inputValue.imageData && (
                    <img
                      src={inputValue.imageData}
                      alt="Selected"
                      style={{maxWidth: '100%', maxHeight: '300px'}}
                      onLoad={handleImageLoad}
                    />
                  )}
                </Box>
                <Box mt={0.5} ml={1}>
                  <input
                    id="raised-button-file"
                    type="file"
                    accept="image/png, image/jpeg"
                    style={{display: 'none'}}
                    onChange={handleImageChange}
                  />
                  <label htmlFor="raised-button-file">
                    <Button variant="contained" component="span">
                      ファイル選択
                    </Button>
                  </label>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <LFCButton onClick={() => onClose(false)}>{t('キャンセル')}</LFCButton>
        <LFCButton color="primary" onClick={onSave}>
          {t('登録')}
        </LFCButton>
      </DialogActions>
    </Dialog>
  );
};

export default WorkImageDialog;
