import {Backdrop, Box, Grid, LinearProgress, Stack, Typography} from '@mui/material';
import {DataGridPro} from '@mui/x-data-grid-pro';
import LFCButton from '_components/inputs/LFCButton';
import ProgressBar from '_components/ProgressBar';
import GenericTemplate from '_templates/GenericTemplate';

import {AlertHistoryDialog} from './components/AlertHistoryDialog';

import {useAlertSettings} from './hooks/useAlertSettings';
import {AlertSettingsDialog} from './components/AlertSettingsDialog';

export const AlertSettingsPage = () => {
  const {
    t,
    startProcess,
    formRef,
    openAlertHistoryDialog,
    closeAlertHistoryDialog,
    openAlertSettingDialog,
    closeAlertSettingDialog,
    isAlertHistoryDlgOpen,
    isAlertSettingDlgOpen,
    apiRef,
    dgColumns,
    dgRows,
    alertId,
    reload,
    devices,
    emails
  } = useAlertSettings();

  return (
    <GenericTemplate title={t('menu.page_name.アラート設定')}>
      {startProcess && (
        <Backdrop sx={{zIndex: 9999}} open={true}>
          <Stack sx={{width: '50%'}} spacing={2} textAlign={'center'}>
            <Typography variant="h4" color="secondary">
              Loading..
            </Typography>
            <LinearProgress color="secondary" />
          </Stack>
        </Backdrop>
      )}
      <Typography variant="subtitle2" marginBottom="20px">
        {t('message.データ監視及び通知の方法を設定します。')}
      </Typography>
      <form ref={formRef}>
        <Grid container rowSpacing={2} alignItems="flex-start" justifyContent="flex-start">
          <Grid item xs={2}>
            <Typography variant="h6" fontWeight="bold">
              {t('監視アイテム一覧')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <LFCButton
              color="primary"
              onClick={() => {
                openAlertSettingDialog('-1');
              }}
            >
              {t('監視の新規追加')}
            </LFCButton>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <LFCButton color="secondary" onClick={openAlertHistoryDialog}>
              {t('通知履歴')}
            </LFCButton>
          </Grid>
          <Grid item xs={12}>
            <Box style={{height: '75vh'}}>
              <DataGridPro
                apiRef={apiRef}
                columns={dgColumns}
                rows={dgRows}
                density={'compact'}
                showCellRightBorder
                showColumnRightBorder
                disableSelectionOnClick
              />
            </Box>
          </Grid>
        </Grid>
      </form>
      <AlertHistoryDialog open={isAlertHistoryDlgOpen} onClose={closeAlertHistoryDialog} />
      <AlertSettingsDialog
        open={isAlertSettingDlgOpen}
        onClose={closeAlertSettingDialog}
        alertId={alertId}
        reload={reload}
        devices={devices}
        emails={emails}
      />
    </GenericTemplate>
  );
};
