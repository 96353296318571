import {Backdrop, LinearProgress, Stack, Typography, useTheme} from '@mui/material';

interface Props {
  startProcess?: boolean;
  screenLock?: boolean; // 画面全体をロックするか
}

const ProgressBar = (props: Props) => {
  const theme = useTheme();
  const isShow = props.startProcess !== undefined ? props.startProcess : true;
  const isScreenLock = props.screenLock !== undefined ? props.screenLock : false;

  if (isShow) {
    return isScreenLock ? (
      <>
        <input type="hidden" id="isProcessing" value="1" />
        <Backdrop sx={{zIndex: 9999}} open={true}>
          <Stack sx={{width: '30%'}} spacing={2} textAlign={'center'}>
            <Typography variant="h4" color="primary">
              LOADING...
            </Typography>
            <LinearProgress color="primary" sx={{height: '0.75em'}} />
          </Stack>
        </Backdrop>
      </>
    ) : (
      <>
        <input type="hidden" id="isProcessing" value="1" />
        <style>
          {`
        @keyframes width-change {
          0% { width: 0%;}
          100% { width: 100%;}
        }`}
        </style>
        <div
          style={{
            animation: 'width-change 2s ease infinite',
            borderTop: `5px solid ${theme.palette.primary.main}`,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 999999999,
            color: theme.palette.primary.main
          }}
        />
      </>
    );
  } else {
    return (
      <>
        <input type="hidden" id="isProcessing" value="0" />
      </>
    );
  }
};

export default ProgressBar;
