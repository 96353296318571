import React from 'react';

import { Tooltip } from '@mui/material';

const SELECT_COLOR = 'rgb(245, 124, 0)';

type DraggableIconProps = {
  id: string;
  label: string;
  color: string;
  iconSize: number;
  position: {absoluteX: number; absoluteY: number};
  selected: boolean;
  isShowLabel: boolean; //ラベル表示フラグ
  isAttention: boolean; //注目フラグ(NGとかの場合にONにする)
  onClick: (id: string) => void;
};

const DraggableIcon = (props: DraggableIconProps) => {
  const style: React.CSSProperties = {
    transform: `translate3d(${props.position.absoluteX}px, ${props.position.absoluteY}px, 0)`,
    width: props.iconSize,
    height: props.iconSize,
    cursor: 'pointer', // クリック可能にするためにカーソルをポインターに設定
    position: 'absolute',
    backgroundColor: props.color,
    border: props.selected ? `3px solid ${SELECT_COLOR}` : '3px solid rgb(96, 125, 139)', // 選択状態に応じてボーダーの色を変更
    zIndex: 1,
    animation: props.color
      ? props.isAttention
        ? 'map-pulse 0.75s infinite, map-flash 0.75s infinite'
        : 'map-flash 3s infinite'
      : ''
  };

  const idStyle: React.CSSProperties = {
    position: 'absolute',
    top: '-3px',
    left: '120%',
    fontSize: `${props.iconSize / 25}em`, // フォントサイズを調整
    color: '#fff', // テキストの色を白に設定
    backgroundColor: props.selected ? SELECT_COLOR : 'rgba(0, 0, 0, 0.5)', // 半透明の黒背景
    padding: '1px', // パディングを追加
    whiteSpace: 'nowrap' // 折り返しを無効にする
  };

  return (
    <Tooltip title={props.label}>
      <div style={style} onClick={() => props.onClick(props.id)}>
        {props.isShowLabel ? <div style={idStyle}>{props.label}&nbsp;</div> : null}
      </div>
    </Tooltip>
  );
};

export default DraggableIcon;
