import LFCButton from '_components/inputs/LFCButton';
import {useSnackbar} from 'notistack';
import {ReactNode, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Stack
} from '@mui/material';

export type ItemRow = {
  idx: number;
  text: string;
};

/**
 * bに無いものを対象にする
 * @param a
 * @param b
 * @returns
 */
const not = (a: ItemRow[], b: ItemRow[]) => {
  return a.filter(value => !b.includes(value));
};

/**
 * bにあるものを対象にする
 * @param a
 * @param b
 * @returns
 */
const intersection = (a: ItemRow[], b: ItemRow[]) => {
  return a.filter(value => b.includes(value));
};

/**
 * aとbを結合する
 * @param a
 * @param b
 * @returns
 */
const union = (a: ItemRow[], b: ItemRow[]) => {
  return [...a, ...not(b, a)];
};

/**
 * 引数
 */
interface Props {
  title: ReactNode;
  items: ItemRow[];
  selectItems: ItemRow[];
  width: string;
  height: string;
  onSelectedChanged: (items: ItemRow[]) => void;
}

/**
 * MovingList
 * @param props
 * @returns
 */
const MovingList = (props: Props) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [checked, setChecked] = useState<ItemRow[]>([]);
  const [left, setLeft] = useState<ItemRow[]>(props.items);
  const [right, setRight] = useState<ItemRow[]>([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  /**
   * リストを選択
   */
  const handleToggle = (value: ItemRow) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: ItemRow[]) => intersection(checked, items).length;

  /**
   * リストをすべて選択
   */
  const handleToggleAll = (items: ItemRow[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  /**
   * チェックしたものを、左→右に移動させる
   */
  const handleCheckedRight = () => {
    setLeft(not(left, leftChecked));
    setRight(right.concat(leftChecked));
    setChecked(not(checked, leftChecked));
  };

  /**
   * チェックしたものを、右→左に移動させる
   */
  // const handleCheckedLeft = () => {
  //   setLeft(left.concat(rightChecked));
  //   setRight(not(right, rightChecked));
  //   setChecked(not(checked, rightChecked));
  // };

  /**
   * すべてを、右→左に移動させる
   */
  // const handleAllLeft = () => {
  //   setLeft(left.concat(right));
  //   setRight([]);
  // };

  /**
   * 外部 itemsデータ変更検知
   */
  useEffect(() => {
    setLeft(props.items);
    setRight([]);
    setChecked([]);
  }, [props.items]);

  /**
   * 外部 selectItemsデータ変更検知
   */
  useEffect(() => {
    if (props.selectItems.length === right.length) return;

    if (props.selectItems.length < right.length) {
      // 削除
      const diffItem = not(right, props.selectItems); // rightから無くなったもだけを抽出
      setLeft(left.concat(diffItem)); //削除されたものを追加
      setRight(props.selectItems); //削除されたものを削除
      setChecked(not(checked, diffItem));
    } else {
      // 追加
      const diffItem = not(left, props.selectItems); // leftに残るものだけ抽出
      setLeft(diffItem);
      setRight(props.selectItems);
      setChecked(not(checked, diffItem));
    }
  }, [props.selectItems]);

  /**
   * 内部 right(選択済)データ変更検知
   */
  useEffect(() => {
    props.onSelectedChanged(right);
  }, [right]);

  const ItemList = (props: {title: ReactNode; items: ItemRow[]; width: string; height: string}) => (
    <Card sx={{width: props.width}}>
      <Box height={props.height} display="flex" flexDirection="column">
        <CardHeader
          sx={{px: 0, py: 1}}
          avatar={
            <Checkbox
              onClick={handleToggleAll(props.items)}
              checked={
                numberOfChecked(props.items) === props.items.length && props.items.length !== 0
              }
              indeterminate={
                numberOfChecked(props.items) !== props.items.length &&
                numberOfChecked(props.items) !== 0
              }
              disabled={props.items.length === 0}
              inputProps={{
                'aria-label': 'all items selected'
              }}
            />
          }
          title={props.title}
          subheader={`${numberOfChecked(props.items)}/${props.items.length}`}
        />
        <Divider />
        <List
          sx={{
            flex: 1,
            bgcolor: 'background.paper',
            overflow: 'auto',
            px: 0,
            py: 0
          }}
          dense
          component="div"
          role="list"
        >
          {props.items.map(r => {
            const labelId = `transfer-list-all-item-${r.idx}-label`;

            return (
              <ListItemButton
                key={r.idx}
                role="listitem"
                onClick={handleToggle(r)}
                sx={{
                  p: 0,
                  m: 0
                }}
              >
                <Checkbox
                  checked={checked.includes(r)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId
                  }}
                />
                <ListItemText id={labelId} primary={r.text} />
              </ListItemButton>
            );
          })}
        </List>
      </Box>
    </Card>
  );

  return (
    <Stack direction="row" spacing={1} height={props.height}>
      <ItemList title={'部位'} items={left} width={props.width} height={props.height}></ItemList>
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <LFCButton
          color="primary"
          onClick={handleCheckedRight}
          disabled={leftChecked.length === 0}
          style={{height: '50px'}}
        >
          &gt;
        </LFCButton>
      </Box>
    </Stack>
  );
};

export default MovingList;
