import LFCButton from '_components/inputs/LFCButton';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import {getLFCData, setLFCDataProc} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import axios, {CancelTokenSource} from 'axios';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import {Box, Button, Grid, Tooltip} from '@mui/material';
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColumns,
  GridRenderCellParams,
  GridRowId,
  useGridApiRef
} from '@mui/x-data-grid-pro';

import MapPointDialog from './_components/MapPointDialog';
import WorkImageDialog from './_components/WorkImageDialog';

/**
 * WorkImagesPage
 * @returns
 */
const WorkImagesPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);

  const apiRef = useGridApiRef();
  const [dgColumns, setDgColumns] = useState<GridColumns>([]);
  const [dgRows, setDgRows] = useState<any>([]);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);

  const cancelSource = useRef<CancelTokenSource | null>(null);

  /**
   * 検索処理
   */
  const doSearch = () => {
    if (cancelSource.current) cancelSource.current.cancel('req cancel');

    setDgRows([]);

    setStartProcess(true);
    cancelSource.current = axios.CancelToken.source();
    Promise.allSettled([
      //画像rootパス取得
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 8000,
        parameters: {},
        cancelToken: cancelSource.current.token,
        t
      }).then(datas => {
        if (datas.length === 0) {
          enqueueSnackbar(`データがありません`, {variant: 'info'});
          return;
        }
        setDgRows(datas);
      })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  /**
   * 機種追加処理
   */
  const doNew = (model: string, imageNo: number) => {
    setPopWork(model);
    setPopImageNo(imageNo);
    setPopOpenWorkImage(true);
  };

  /**
   * 機種削除処理
   */
  const doDelete = (isOk: boolean) => {
    if (!isOk) return;

    if (cancelSource.current) cancelSource.current.cancel('req cancel');

    const selectedWorks = dgRows
      .filter((r: any) => selectionModel.includes(r.id))
      .map((r: any) => ({work: r.work, imageNo: -1}));

    if (selectedWorks.length === 0) {
      enqueueSnackbar(`削除対象を選択してください`, {variant: 'error'});
      return;
    }

    setStartProcess(true);
    cancelSource.current = axios.CancelToken.source();
    Promise.allSettled([
      setLFCDataProc({
        snack: enqueueSnackbar,
        name: '削除処理',
        invoke_name: 'DeleteWorkImage',
        parameters: {
          works: selectedWorks
        },
        cancelToken: cancelSource.current.token,
        t
      })
        .then((datas: []) => {
          doSearch();
        })
        .catch(err => {
          console.error(err);
          enqueueSnackbar(`削除に失敗しました`, {variant: 'error'});
        })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  /**
   * 部位編集処理
   */
  const doMapEdit = (
    model: string,
    imageNo: number,
    path: string,
    aspectRatio: number,
    iconRatio: number
  ) => {
    setPopWork(model);
    setPopImageNo(imageNo);
    setPopImagePath(path);
    setPopImagePath(path);
    setPopAspectRatio(aspectRatio);
    setPopIconRatio(iconRatio);
    setPopOpenMapPoint(true);
  };

  useEffect(() => {
    // Gridのカラム設定
    setDgColumns([
      {field: 'id', headerName: '', description: '', hide: true},
      {
        field: 'work',
        headerName: `${t('機種')}`,
        description: `${t('機種')}`,
        width: 200
      },
      ...[...Array(5)].map((r: any, i: number) => ({
        field: `image${i + 1}`,
        headerName: `${i + 1}`,
        description: `${i + 1}`,
        renderCell: (prms: GridRenderCellParams) => {
          const timestamp = new Date().getTime();
          const imgPath = `${prms.value}?${timestamp}`;

          return prms.value == null ? (
            <Grid container justifyContent="center">
              <Button color="primary" onClick={() => doNew(prms.row['work'], i + 1)}>
                <AddCircleIcon />
              </Button>
            </Grid>
          ) : (
            <Tooltip title={'ダブルクリックで編集'} followCursor>
              <img
                src={imgPath}
                width="100%"
                height="100%"
                alt=""
                onDoubleClick={() =>
                  doMapEdit(
                    prms.row['work'],
                    i + 1,
                    imgPath,
                    prms.row[`aspect_ratio${i + 1}`],
                    prms.row[`icon_ratio${i + 1}`]
                  )
                }
                style={{cursor: 'pointer', objectFit: 'fill'}}
              />
            </Tooltip>
          );
        },
        width: 200
      }))
    ]);

    doSearch();

    return () => {
      if (cancelSource.current) cancelSource.current.cancel('req cancel');
    };
  }, []);

  // ImageDialog用の状態管理
  const [popOpenWorkImage, setPopOpenWorkImage] = useState(false);
  const [popWork, setPopWork] = useState('');
  const [popImageNo, setPopImageNo] = useState(1);
  const [popImagePath, setPopImagePath] = useState('');
  const [popAspectRatio, setPopAspectRatio] = useState(0);
  const [popIconRatio, setPopIconRatio] = useState(1);
  const handleCloseWorkImage = (isRefresh: boolean) => {
    setPopOpenWorkImage(false);
    if (isRefresh) doSearch();
  };

  const [popOpenMapPoint, setPopOpenMapPoint] = useState(false);
  const handleCloseMapPoint = (isRefresh: boolean) => {
    setPopOpenMapPoint(false);
    if (isRefresh) doSearch();
  };

  return (
    <GenericTemplate title={'ワーク画像登録'}>
      <ProgressBar startProcess={startProcess} />
      <Grid
        container
        spacing={2}
        rowSpacing={2}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Grid item xs={12}>
          <LFCButton color="primary" onClick={doSearch}>
            {t('message.最新の情報に更新')}
          </LFCButton>
        </Grid>
        <Grid item xs={12}>
          <Box style={{height: '75vh'}}>
            <DataGridPro
              apiRef={apiRef}
              columns={dgColumns}
              rows={dgRows}
              density={'compact'}
              onSelectionModelChange={setSelectionModel}
              selectionModel={selectionModel}
              showCellRightBorder
              showColumnRightBorder
              checkboxSelection
              disableSelectionOnClick
              initialState={{
                pinnedColumns: {
                  left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'work'],
                  right: ['actions']
                }
              }}
              rowHeight={100}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <LFCFormRowGroup>
            <LFCButton color="primary" onClick={() => doNew('', 1)}>
              {t('新規')}
            </LFCButton>
            <LFCButton
              color="secondary"
              onClick={doDelete}
              confirmMessage={'削除します。よろしいですか？'}
            >
              {t('削除')}
            </LFCButton>
          </LFCFormRowGroup>
        </Grid>
      </Grid>
      <WorkImageDialog
        open={popOpenWorkImage}
        onClose={handleCloseWorkImage}
        work={popWork}
        imageNo={popImageNo}
      ></WorkImageDialog>
      <MapPointDialog
        open={popOpenMapPoint}
        onClose={handleCloseMapPoint}
        work={popWork}
        imageNo={popImageNo}
        imagePath={popImagePath}
        aspectRatio={popAspectRatio}
        iconRatio={popIconRatio}
      ></MapPointDialog>
    </GenericTemplate>
  );
};

export default WorkImagesPage;
