import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import {getLFCData} from '_logics/LFCUtil';
import axios from 'axios';
import {useEffect, useState} from 'react';

interface AlertSettingsProps {
  onClose: () => void;
  alertId: string;
  open: boolean;
  reload: () => Promise<void>;
  devices: any[];
  emails: any[];
}

export const useAlertSettingsDialog = ({
  onClose,
  alertId,
  open,
  reload,
  devices,
  emails
}: AlertSettingsProps) => {
  const [email, setEmail] = useState('');
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const [notifications, setNotifications] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const DAYS_MAP = {
    月: 'Mon',
    火: 'Tue',
    水: 'Wed',
    木: 'Thur',
    金: 'Fri',
    土: 'Sat',
    日: 'Sun'
  };
  const [alertName, setAlertName] = useState<string>('');
  const [active, setActive] = useState<boolean>(true);
  const [machine, setMachine] = useState<string>('');
  const [rule, setRule] = useState<string>('1');
  const [processType, setProcessType] = useState<string>('1');
  const [interval, setInterval] = useState<string>('1');
  const [days, setDays] = useState<string[]>(['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun']);
  const [from, setFrom] = useState<string>('08:00');
  const [to, setTo] = useState<string>('22:00');

  /*
   * メールアドレス関連の処理
   */
  const handleAddNotification = () => {
    if (!emails.some(e => e.email === email)) {
      enqueueSnackbar(`${t('message.メールアドレスが存在しません')}`, {
        variant: 'error',
        persist: true
      });
      return;
    }
    setNotifications([
      ...notifications,
      {email: emails.find(e => e.email === email).id, abnormalOnly: false}
    ]);
    setEmail('');
  };

  const handleDeleteNotification = (index: number) => {
    setNotifications(notifications.filter((_: any, i: number) => i !== index));
  };

  const handleCheckboxChange = (index: string | number, field: string | number) => {
    setNotifications((prevNotifications: any[]) =>
      prevNotifications.map((notification, i) =>
        i === index
          ? {
              ...notification,
              abnormalOnly: field === 'abnormalOnly'
            }
          : notification
      )
    );
  };

  /*
   * 設定保存処理
   */
  const onSubmit = async () => {
    let newAlertId = '';
    try {
      setIsLoading(true);
      const payload = {
        active: active ? 1 : 0,
        alert_name: alertName,
        machine,
        rule: Number(rule),
        process_type: Number(processType),
        from: from,
        to: to,
        alert_interval: interval,
        schedule: days.length
          ? `(${days
              .filter(day => Object.values(DAYS_MAP).includes(day))
              .map(day => `'${day}'`)
              .join(', ')})`
          : '{}'
      };
      /* 編集の場合 */
      if (alertId !== '-1') {
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 313,
          parameters: {...payload, alert_id: alertId},
          name: `${t('アラート設定 保存')}`,
          cancelToken: source.token,
          t
        });
        newAlertId = alertId;
      } else {
        /* 新規追加の場合 */
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 312,
          parameters: payload,
          name: `${t('アラート設定 保存')}`,
          cancelToken: source.token,
          t
        }).then(ds => {
          newAlertId = ds[0].alert_id;
        });
      }
      /* 通知先一覧更新処理 */
      // 既存の通知データを取得
      const existingNotifications = await getLFCData({
        snack: enqueueSnackbar,
        sql_id: 317, // 既存の通知データ取得用のクエリ
        parameters: {alertId: newAlertId},
        name: '',
        cancelToken: source.token,
        t
      });

      // 差分を計算
      const notificationsToDelete = existingNotifications.filter(
        (item: any) => !notifications.some((n: any) => n.email === item.recipients)
      );

      const notificationsToUpdate = notifications.filter((item: any) =>
        existingNotifications.some(
          (n: any) => n.recipients === item.email && n.receive_mail !== (item.abnormalOnly ? 1 : 0)
        )
      );

      const notificationsToAdd = notifications.filter(
        (item: any) => !existingNotifications.some((n: any) => n.recipients === item.email)
      );

      // 削除処理
      await Promise.all(
        notificationsToDelete.map(async (item: any) => {
          await getLFCData({
            snack: enqueueSnackbar,
            sql_id: 316,
            parameters: {alert_id: newAlertId, recipients: item.recipients},
            name: '',
            cancelToken: source.token,
            t
          });
        })
      );

      // 更新処理
      await Promise.all(
        notificationsToUpdate.map(async (item: any) => {
          await getLFCData({
            snack: enqueueSnackbar,
            sql_id: 315,
            parameters: {
              alert_id: newAlertId,
              recipients: emails.find(email => email.id === item.email).id,
              receive_mail: item.abnormalOnly ? 1 : 0
            },
            name: '',
            cancelToken: source.token,
            t
          });
        })
      );

      // 追加処理
      await Promise.all(
        notificationsToAdd.map(async (item: any) => {
          await getLFCData({
            snack: enqueueSnackbar,
            sql_id: 315,
            parameters: {
              alert_id: newAlertId,
              recipients: emails.find(email => email.id === item.email).id,
              receive_mail: item.abnormalOnly ? 1 : 0
            },
            name: '',
            cancelToken: source.token,
            t
          });
        })
      );
      /* 更新 */
      await reload();
      onClose();
      enqueueSnackbar(t('message.設定の保存が完了しました。'), {variant: 'success'});
    } catch (e) {
      console.log(e);
      enqueueSnackbar(`${t('message.設定の保存に失敗しました。')}:${e}`, {
        variant: 'error',
        persist: true
      });
    }
    setIsLoading(false);
  };

  /*
   * 設定削除処理
   */
  const onDelete = async () => {
    try {
      setIsLoading(true);
      await getLFCData({
        snack: enqueueSnackbar,
        sql_id: 314,
        parameters: {alert_id: alertId},
        cancelToken: source.token,
        t
      });
      await reload();

      enqueueSnackbar(t('message.設定の削除が完了しました。'), {variant: 'success'});
      onClose();
    } catch (e) {
      console.log({e});

      enqueueSnackbar(t('message.設定の削除に失敗しました。'), {variant: 'success'});
    }
    setIsLoading(false);
  };

  const reset = () => {
    setAlertName('');
    setRule('1');
    setMachine('');
    setInterval('1');
    setFrom('08:00');
    setTo('22:00');
    setDays(['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun']);
    setNotifications([]);
  };

  /*
   * 編集時のみデータ取得
   */
  useEffect(() => {
    const fetchData = async () => {
      if (!open) return;

      try {
        setIsLoading(true);
        reset();
        if (alertId !== '-1') {
          Promise.allSettled([
            await getLFCData({
              snack: enqueueSnackbar,
              sql_id: 311,
              parameters: {alertId},
              cancelToken: source.token,
              t
            }).then(ds => {
              setActive(ds[0]['Active?'] === 1);
              setAlertName(ds[0].alert_name);
              setRule(String(ds[0].Rule));
              setMachine(ds[0].machine);
              setInterval(String(ds[0].alert_interval));
              setFrom(ds[0].From.split(':').slice(0, 2).join(':'));
              setTo(ds[0].To.split(':').slice(0, 2).join(':'));
              setDays(
                ds[0].schedule
                  .replace(/[()']/g, '') // 不要な括弧やシングルクォートを削除
                  .replace(/\.\s*/g, ',') // 誤って `Thur'.` のような表記に対応
                  .split(',') // カンマで分割
                  .map((day: string) => day.trim())
              );
              ds[0].recipient_details.map((item: any) => {
                setNotifications((prev: any) =>
                  [
                    ...prev,
                    {
                      email: item.recipients,
                      abnormalOnly: item.receive_mail === 1
                    }
                  ].sort((a, b) => a.email.localeCompare(b.email))
                );
              });
            })
          ]);
        }
      } catch (error) {
        console.error('Error occurred:', error);
      }
      setIsLoading(false);
    };

    fetchData();

    // クリーンアップ関数
    return () => {
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, [open]);

  /*
   * 保存ボタンのdisabled判定
   */
  const disabled =
    alertName === '' ||
    machine === '' ||
    rule === '' ||
    devices.length === 0 ||
    from === '' ||
    to === '';

  return {
    disabled,
    onSubmit,
    onDelete,
    active,
    setActive,
    alertName,
    setAlertName,
    machine,
    setMachine,
    devices,
    rule,
    setRule,
    processType,
    setProcessType,
    from,
    setFrom,
    to,
    setTo,
    DAYS_MAP,
    days,
    setDays,
    interval,
    setInterval,
    email,
    setEmail,
    handleAddNotification,
    notifications,
    handleDeleteNotification,
    handleCheckboxChange,
    isLoading,
    emails,
    t
  };
};
