import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCButton from '_components/inputs/LFCButton';
import ProgressBar from '_components/ProgressBar';
import {getLFCData, setLFCDataProc} from '_logics/LFCUtil';
import axios, {CancelTokenSource} from 'axios';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Slider,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';

import MapPointEditor, {MapIconProps} from './MapPointEditor';
import MovingList, {ItemRow} from './MovingList';

const ICON_COLOR = 'rgb(255, 193, 7)';

/**
 * 引数
 */
interface Props {
  open: boolean;
  onClose: (isRefresh: boolean) => void;
  work: string;
  imageNo: number;
  imagePath: string;
  aspectRatio: number;
  iconRatio: number;
}

/**
 * Map登録ダイアログ
 * @param props
 * @returns
 */
const MapPointDialog = (props: Props) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const [maps, setMaps] = useState<ItemRow[]>([]);
  const [selectMaps, setSelectMaps] = useState<ItemRow[]>([]);
  const [icons, setIcons] = useState<MapIconProps[]>([]);

  const [imgWidth, setImgWidth] = useState(900);
  const [imgHeight, setImgHeight] = useState(0);
  const [maxWidth, setMaxWidth] = useState(900);
  const containerRef = useRef<HTMLDivElement>(null);
  const [iconRatio, setIconRatio] = useState(1);

  const cancelSource = useRef<CancelTokenSource | null>(null);

  /**
   * ウィンドウリサイズイベント
   */
  const updateWidth = () => {
    if (containerRef.current) {
      const maxW = containerRef.current.offsetWidth - 400;
      setMaxWidth(maxW);
      setImgWidth(maxW * 0.9);
    }
  };

  /**
   * icon編集コールバック（icon削除時）
   */
  const onIconChanged = (newIcons: MapIconProps[]) => {
    // Mapリストから無くなったものを除外
    const newSelectMaps = selectMaps.filter(r => newIcons.some(r2 => r2.id === r.text));
    setSelectMaps(newSelectMaps);

    // エディタのものと同期
    setIcons(newIcons);
  };

  /**
   * リスト → エディタへ追加処理
   */
  const onListToEdit = (newSelectedMaps: ItemRow[]) => {
    setSelectMaps(newSelectedMaps);

    const diffMaps = newSelectedMaps.filter(r => !icons.some(r2 => r2.id === r.text));
    setIcons([
      ...icons,
      ...diffMaps.map((r, i) => {
        return {
          id: r.text,
          color: ICON_COLOR,
          position: {relativeX: 2, relativeY: 2 + i}
        };
      })
    ]);
  };

  /**
   * 削除処理
   */
  const onDelete = (isOk: boolean) => {
    if (!isOk) return;

    if (cancelSource.current) cancelSource.current.cancel('req cancel');

    setStartProcess(true);
    cancelSource.current = axios.CancelToken.source();
    Promise.allSettled([
      setLFCDataProc({
        snack: enqueueSnackbar,
        name: '削除処理',
        invoke_name: 'DeleteWorkImage',
        parameters: {
          works: [{work: props.work, imageNo: props.imageNo}]
        },
        cancelToken: cancelSource.current.token,
        t
      })
        .then((datas: []) => {
          onClose(true);
        })
        .catch(err => {
          console.error(err);
          enqueueSnackbar(`削除に失敗しました`, {variant: 'error'});
        })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  /**
   * クリア処理
   */
  const onClear = (isOk: boolean) => {
    if (!isOk) return;

    setSelectMaps([]);
    setIcons([]);
  };

  /**
   * 登録処理
   */
  const onSave = () => {
    if (cancelSource.current) cancelSource.current.cancel('req cancel');

    setStartProcess(true);
    cancelSource.current = axios.CancelToken.source();
    Promise.allSettled([
      setLFCDataProc({
        snack: enqueueSnackbar,
        name: '登録処理',
        invoke_name: 'CreateWorkImagePoints',
        parameters: {
          work: props.work,
          imageNo: props.imageNo,
          maps: icons.map(r => ({
            map: r.id,
            relative_x: r.position.relativeX,
            relative_y: r.position.relativeY
          })),
          icon_ratio: iconRatio
        },
        cancelToken: cancelSource.current.token,
        t
      })
        .then((datas: []) => {
          onClose(true);
        })
        .catch(err => {
          console.error(err);
          enqueueSnackbar(`登録に失敗しました`, {variant: 'error'});
        })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  /**
   * Close処理
   */
  const onClose = (isRefresh: boolean) => {
    props.onClose(isRefresh);
  };

  useEffect(() => {
    // ウィンドウのリサイズイベントにリスナーを追加
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);

      if (cancelSource.current) cancelSource.current.cancel('req cancel');
    };
  }, []);

  /**
   * 外部 open変更検知
   */
  useEffect(() => {
    if (!props.open) {
      // Closeした時は初期化
      setMaps([]);
      setIconRatio(0);
      setSelectMaps([]);
      setIcons([]);
      return;
    }

    // 画像サイズの初期の幅を設定
    setTimeout(updateWidth, 0);

    setMaps([]);
    setIconRatio(props.iconRatio);

    setStartProcess(true);
    cancelSource.current = axios.CancelToken.source();
    Promise.allSettled([
      // MapList取得
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 8001,
        parameters: {work: props.work},
        cancelToken: cancelSource.current.token,
        t
      }).then((ds: []) => {
        const tmp = ds.map((item: any, i: number) => {
          return {idx: i, text: item['map']};
        });
        setMaps(tmp);
      })
    ]).then(() => {
      setStartProcess(false);
    });
  }, [props.open]);

  /**
   * 外部 work変更検知
   */
  // useEffect(() => {
  //   if (!props.open) return;

  //   setMaps([]);

  //   setStartProcess(true);
  //   cancelSource.current = axios.CancelToken.source();
  //   Promise.allSettled([
  //     // MapList取得
  //     getLFCData({
  //       snack: enqueueSnackbar,
  //       sql_id: 8001,
  //       parameters: {work: props.work},
  //       cancelToken: cancelSource.current.token,
  //       t
  //     }).then((ds: []) => {
  //       const tmp = ds.map((item: any, i: number) => {
  //         return {idx: i, text: item['map']};
  //       });
  //       setMaps(tmp);
  //     })
  //   ]).then(() => {
  //     setStartProcess(false);
  //   });
  // }, [props.work]);

  /**
   * 内部 maps変更検知
   */
  useEffect(() => {
    if (!props.open || maps.length === 0) return;

    setStartProcess(true);
    cancelSource.current = axios.CancelToken.source();
    Promise.allSettled([
      // IconList取得
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 8002,
        parameters: {work: props.work, image_no: props.imageNo},
        cancelToken: cancelSource.current.token,
        t
      }).then((ds: []) => {
        if (ds.length === 0) {
          setSelectMaps([]);
          setIcons([]);
          return;
        }

        const savedIcons: MapIconProps[] = ds.map((item: any) => {
          return {
            id: item['map'],
            color: ICON_COLOR,
            position: {relativeX: item['relative_x'], relativeY: item['relative_y']}
          };
        });

        // Mapリストからエディタに反映済のデータを除外
        const newSelectMaps = maps.filter(r => savedIcons.some(r2 => r2.id === r.text));
        setSelectMaps(newSelectMaps);

        setIcons(savedIcons);
      })
    ]).then(() => {
      setStartProcess(false);
    });
  }, [maps]);

  /**
   * 外部 縦 or 横幅変更検知
   */
  useEffect(() => {
    setImgHeight(imgWidth * props.aspectRatio);
  }, [imgWidth, props.aspectRatio]);

  return (
    <Dialog open={props.open} onClose={() => onClose(false)} fullScreen>
      <ProgressBar startProcess={startProcess} screenLock />

      <LFCDialogTitle onClose={() => onClose(false)}>部位登録</LFCDialogTitle>
      <DialogContent ref={containerRef} dividers>
        <Stack direction="row" spacing={1}>
          <Box>
            {/* Mapリスト */}
            <MovingList
              title={'部位'}
              items={maps}
              selectItems={selectMaps}
              onSelectedChanged={onListToEdit}
              width="250px"
              height="80vh"
            ></MovingList>
          </Box>
          <Box width={'100%'}>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={6}>
                <Stack direction="row" spacing={2}>
                  <Box maxWidth={'300px'}>
                    <Chip size="small" label="機種" />
                    <Tooltip title={props.work} arrow>
                      <Typography mt={0.5} ml={1} noWrap>
                        {props.work}
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Box width={'100px'}>
                    <Chip size="small" label="画像No" />
                    <Typography mt={0.5} ml={1}>
                      {props.imageNo}
                    </Typography>
                  </Box>
                  <Box width={'200px'}>
                    <Chip size="small" variant="outlined" label="アイコンサイズ" />
                    <Box ml={1}>
                      <Stack spacing={1} direction="row" sx={{alignItems: 'center'}}>
                        <RemoveIcon />
                        <Slider
                          size="small"
                          valueLabelDisplay="off"
                          value={iconRatio}
                          step={0.1}
                          min={0.5}
                          max={1.5}
                          onChange={(event: Event, newValue: number | number[]) =>
                            setIconRatio(newValue as number)
                          }
                        />
                        <AddIcon />
                      </Stack>
                    </Box>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack direction="row" spacing={2}>
                  <Box display="flex" justifyContent="flex-end" width="100%">
                    <Box width={'175px'} mr={1}>
                      <Stack spacing={1} direction="row" sx={{alignItems: 'center', mb: 1}}>
                        <ZoomOutIcon />
                        <Slider
                          size="small"
                          valueLabelDisplay="off"
                          value={imgWidth}
                          step={50}
                          min={500}
                          max={maxWidth}
                          onChange={(event: Event, newValue: number | number[]) =>
                            setImgWidth(newValue as number)
                          }
                        />
                        <ZoomInIcon />
                      </Stack>
                    </Box>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                {/* Mapエディタ */}
                <MapPointEditor
                  containerWidth={imgWidth}
                  containerHeight={imgHeight}
                  imgPath={props.imagePath}
                  icons={icons}
                  iconRatio={iconRatio}
                  onIconChanged={onIconChanged}
                ></MapPointEditor>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="start" width="100%">
          <LFCButton
            color="secondary"
            onClick={onDelete}
            confirmMessage={'設定された画像とアイコンを全てクリアします。よろしいですか？'}
          >
            {t('画像削除')}
          </LFCButton>
        </Box>
        <Box display="flex" justifyContent="end" width="100%">
          <LFCButton
            color="secondary"
            onClick={onClear}
            confirmMessage={'設定されたアイコンを全てクリアします。よろしいですか？'}
          >
            {t('全クリア')}
          </LFCButton>
          <Box pl={5}>
            <LFCButton onClick={() => onClose(false)}>{t('キャンセル')}</LFCButton>
          </Box>
          <Box pl={1}>
            <LFCButton color="primary" onClick={onSave}>
              {t('登録')}
            </LFCButton>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default MapPointDialog;
