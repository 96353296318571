import Decimal from 'decimal.js-light';
import React, {useEffect, useState} from 'react';

import {DndContext, DragEndEvent, useDraggable, useDroppable} from '@dnd-kit/core';
import {Alert, Box, Tooltip} from '@mui/material';

type DraggableIconProps = {
  id: string;
  color: string;
  iconSize: number;
  position: {absoluteX: number; absoluteY: number};
  // onPositionChange: (id: string, x: number, y: number) => void;
};

const DraggableIcon = (props: DraggableIconProps) => {
  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: props.id
  });

  const style: React.CSSProperties = {
    transform: transform
      ? `translate3d(${props.position.absoluteX + transform.x}px, ${
          props.position.absoluteY + transform.y
        }px, 0)`
      : `translate3d(${props.position.absoluteX}px, ${props.position.absoluteY}px, 0)`,
    width: props.iconSize,
    height: props.iconSize,
    cursor: 'move',
    position: 'absolute',
    backgroundColor: props.color,
    border: 'solid 3px rgb(96, 125, 139)',
    zIndex: 1,
    animation: ' map-flash 2s infinite'
  };

  const idStyle: React.CSSProperties = {
    position: 'absolute',
    top: '-3px',
    left: '120%',
    fontSize: `${props.iconSize / 25}em`, // フォントサイズを調整
    color: '#fff', // テキストの色を白に設定
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // 半透明の黒背景
    padding: '1px', // パディングを追加
    whiteSpace: 'nowrap' // 折り返しを無効にする
  };

  return (
    <Tooltip title={props.id}>
      <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
        <div style={idStyle}>{props.id}</div>
      </div>
    </Tooltip>
  );
};

export type MapIconProps = {
  id: string;
  color: string;
  position: {relativeX: number; relativeY: number};
};

type MapPointEditorProps = {
  containerWidth: number;
  containerHeight: number;
  imgPath: string;
  icons: MapIconProps[];
  iconRatio: number;
  onIconChanged: (resIcons: MapIconProps[]) => void;
};

const MapPointEditor = (props: MapPointEditorProps) => {
  const {setNodeRef: setBackgroundRef} = useDroppable({id: 'background'});
  const iconSize = props.containerWidth * (props.iconRatio / 50);
  const [relativeIcons, setRelativeIcons] = useState<MapIconProps[]>(props.icons);
  const [limitX, setLimitX] = useState(props.containerWidth - iconSize);
  const [limitY, setLimitY] = useState(props.containerHeight - iconSize);

  /**
   * 相対座標→絶対座標変換
   * @param relativeX
   * @param relativeY
   * @returns
   */
  const convAbsolutePos = (relativeX: number, relativeY: number) => {
    return {
      x: new Decimal(relativeX).div(100).mul(props.containerWidth).toNumber(),
      y: new Decimal(relativeY).div(100).mul(props.containerHeight).toNumber()
    };
  };

  /**
   * 絶対座標→相対座標変換
   * @param absoluteX
   * @param absoluteY
   * @returns
   */
  const convRelativePos = (absoluteX: number, absoluteY: number) => {
    return {
      x: new Decimal(absoluteX).div(props.containerWidth).mul(100).toNumber(),
      y: new Decimal(absoluteY).div(props.containerHeight).mul(100).toNumber()
    };
  };

  /**
   * アイコンドラック終了イベント
   * @param event
   * @returns
   */
  const handleDragEnd = (event: DragEndEvent) => {
    const {id} = event.active;
    const {x, y} = event.delta;

    // 操作対象アイコン取得
    const pickIcon = relativeIcons.filter(icon => icon.id === id)[0];
    const {x: oldX, y: oldY} = convAbsolutePos(
      pickIcon.position.relativeX,
      pickIcon.position.relativeY
    );

    // 枠外にドラックした場合は消す
    const fixX = Math.min(Math.max(oldX + x, -1), limitX);
    const fixY = Math.min(Math.max(oldY + y, -1), limitY);
    if (fixX === -1 || fixX === limitX || fixY === -1 || fixY === limitY) {
      setRelativeIcons(prevIcons => prevIcons.filter(icon => icon.id !== id));
      return;
    }

    // 絶対→相対値に変換して、アイコンデータを更新
    const {x: relativeFixX, y: relativeFixY} = convRelativePos(fixX, fixY);
    pickIcon.position = {relativeX: relativeFixX, relativeY: relativeFixY};

    setRelativeIcons(prevIcons => prevIcons.map(icon => (icon.id === id ? pickIcon : icon)));
  };

  /**
   * 外部 縦 or 横幅変更検知
   */
  useEffect(() => {
    setLimitX(props.containerWidth - iconSize);
    setLimitY(props.containerHeight - iconSize);
  }, [props.containerWidth, props.containerHeight]);

  /**
   * 外部 iconデータ変更検知
   */
  useEffect(() => {
    setRelativeIcons(props.icons);
  }, [props.icons]);

  /**
   * 内部 iconデータ変更検知
   */
  useEffect(() => {
    if (props.icons.length !== relativeIcons.length) {
      //iconデータが追加削除等された場合にも検知できるようにコールバック
      props.onIconChanged(relativeIcons);
    }
  }, [relativeIcons]);

  return (
    <Box>
      <Alert
        severity="info"
        sx={{mb: 1, width: '25em'}}
      >{`アイコンを枠外に移動するとクリアできます`}</Alert>
      <DndContext onDragEnd={handleDragEnd}>
        {/* メインコンテナ */}
        <div
          style={{
            position: 'relative',
            width: `${props.containerWidth}px `,
            height: `${props.containerHeight}px`,
            border: '3px solid gray'
          }}
        >
          {/* 背景画像エリア */}
          <div
            ref={setBackgroundRef}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundImage: `url(${props.imgPath})`,
              backgroundSize: `${props.containerWidth}px ${props.containerHeight}px`,
              backgroundPosition: 'top left',
              backgroundRepeat: 'no-repeat',
              overflow: 'visible'
            }}
          />
          {/* アイコン */}
          {relativeIcons.map(icon => {
            return (
              <DraggableIcon
                key={icon.id}
                id={icon.id}
                color={icon.color}
                iconSize={iconSize}
                position={(() => {
                  const res = convAbsolutePos(icon.position.relativeX, icon.position.relativeY);
                  return {absoluteX: res.x, absoluteY: res.y};
                })()}
                // onPositionChange={(id, x, y) =>
                //   setIcons(prevIcons =>
                //     prevIcons.map(icon => (icon.id === id ? {...icon, position: {x, y}} : icon))
                //   )
                // }
              />
            );
          })}
        </div>
      </DndContext>
    </Box>
  );
};

export default MapPointEditor;
