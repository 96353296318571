import {
  Button,
  TextField,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Typography,
  Paper,
  FormControlLabel,
  DialogTitle,
  Dialog,
  DialogContent,
  InputLabel,
  RadioGroup,
  Radio,
  Backdrop,
  Stack,
  LinearProgress,
  Tooltip,
  Autocomplete
} from '@mui/material';
import {useAlertSettingsDialog} from './hooks/useAlertSettingsDialog';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface AlertSettingsDialogProps {
  open: boolean;
  onClose: () => void;
  alertId: string;
  reload: () => Promise<void>;
  devices: any[];
  emails: any[];
}

export const AlertSettingsDialog = ({
  open,
  onClose,
  alertId,
  reload,
  devices,
  emails
}: AlertSettingsDialogProps) => {
  const {
    onSubmit,
    onDelete,
    active,
    setActive,
    alertName,
    setAlertName,
    machine,
    setMachine,
    rule,
    setRule,
    processType,
    setProcessType,
    from,
    setFrom,
    to,
    setTo,
    DAYS_MAP,
    days,
    setDays,
    interval,
    setInterval,
    email,
    setEmail,
    handleAddNotification,
    notifications,
    handleDeleteNotification,
    handleCheckboxChange,
    disabled,
    isLoading,
    t
  } = useAlertSettingsDialog({onClose, alertId, open, reload, devices, emails});
  const HelpTooltip = ({title}: {title: string}) => {
    return (
      <Tooltip title={title}>
        <HelpOutlineIcon />
      </Tooltip>
    );
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{t('アラート設定 : 詳細')}</DialogTitle>
      {isLoading && (
        <Backdrop sx={{zIndex: 9999}} open={true}>
          <Stack sx={{width: '50%'}} spacing={2} textAlign={'center'}>
            <Typography variant="h4" color="secondary">
              Loading..
            </Typography>
            <LinearProgress color="secondary" />
          </Stack>
        </Backdrop>
      )}
      <DialogContent>
        {/* Save buttons */}
        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 16}}>
          <div style={{display: 'flex', justifyContent: 'justify-start', gap: 20}}>
            <Button variant="contained" color="primary" onClick={onSubmit} disabled={disabled}>
              {t('設定の保存')}
            </Button>
            <Button variant="outlined" color="primary" onClick={onClose}>
              {t('保存せず戻る')}
            </Button>
          </div>

          <Button
            variant="contained"
            color="secondary"
            onClick={onDelete}
            disabled={alertId === '-1'}
          >
            {t('設定を削除')}
          </Button>
        </div>

        {/* 監視の実行 */}
        <div
          style={{
            marginBottom: 16,
            border: '3px solid',
            width: '100%',
            borderRadius: 10,
            padding: 16
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Typography fontSize={20} marginBottom={3}>
              {t('監視の実行')}
            </Typography>
            <HelpTooltip
              title={t(
                'message.有効を選択時は監視を行い、通知者へ監視結果を送信します。無効を選択時は監視や結果送信は行いませんが、設定は残ります。'
              )}
            />
          </div>

          <RadioGroup row>
            <FormControlLabel
              value="enabled"
              control={<Radio checked={active} onChange={() => setActive(!active)} />}
              label={t('有効')}
            />
            <FormControlLabel
              value="disabled"
              control={<Radio checked={!active} onChange={() => setActive(!active)} />}
              label={t('無効')}
            />
          </RadioGroup>
        </div>

        {/* アラート名 */}
        <div
          style={{
            marginBottom: 16,
            border: '3px solid',
            width: '100%',
            borderRadius: 10,
            padding: 16
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Typography fontSize={20} marginBottom={3}>
              {t('アラート名')}
            </Typography>
            <HelpTooltip
              title={t(
                'message.この設定に対して任意に名前を付けられます。通知の件名として使用されます。'
              )}
            />
          </div>
          <TextField
            label={t('アラート名')}
            value={alertName}
            onChange={e => setAlertName(e.target.value)}
            inputProps={{maxLength: 100}}
            style={{width: '100%'}}
          />
        </div>

        {/* 監視対象 */}
        <div
          style={{
            marginBottom: 16,
            border: '3px solid',
            width: '100%',
            borderRadius: 10,
            padding: 16
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Typography fontSize={20} marginBottom={3}>
              {t('監視対象')}
            </Typography>
            <HelpTooltip
              title={t(
                'message.監視する装置及び対象のデータを選択します。どの装置で収集しているデータであるか、その中でどのデータを監視するかを選択します。'
              )}
            />
          </div>
          <InputLabel>{t('監視装置')}</InputLabel>
          <Select
            style={{marginBottom: 20, width: '100%'}}
            value={machine}
            onChange={e => setMachine(e.target.value)}
          >
            {devices.map(item => (
              <MenuItem value={item.label} key={item.label}>
                {item.label}
              </MenuItem>
            ))}
          </Select>

          <InputLabel>{t('監視アイテム')}</InputLabel>
          <Select
            value={processType}
            onChange={e => setProcessType(e.target.value)}
            style={{width: '100%'}}
          >
            <MenuItem value="1">
              {t('日別NG率傾向の過去３０日比較（欠陥数/検査数比率 U管理図）')}
            </MenuItem>
            <MenuItem value="2">
              {t('日別NG率傾向の過去３０日比較（不良品数/検査数比率 P管理図）')}
            </MenuItem>
          </Select>
        </div>

        {/* 判定ルール */}
        <div
          style={{
            marginBottom: 16,
            border: '3px solid',
            width: '100%',
            borderRadius: 10,
            padding: 16
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Typography fontSize={20} marginBottom={3}>
              {t('判定ルール')}
            </Typography>
            <HelpTooltip
              title={t(
                'message.監視アイテムについて異常等の判定ルールを選択します。・3σ異常判定：標準偏差を算出します。正規分布で+1σ以下を「良い」、+1～2σを「標準的」、+2～3σを「悪い」、+3σ(UCL)以上を「管理外れ」と表記。'
              )}
            />
          </div>
          <RadioGroup row>
            <FormControlLabel
              value={rule}
              control={<Radio checked={rule === '1'} />}
              label={t('3σ異常判定')}
            />
          </RadioGroup>
        </div>

        {/* 監視スケジュール */}
        <div
          style={{
            marginBottom: 16,
            border: '3px solid',
            width: '100%',
            borderRadius: 10,
            padding: 16
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Typography fontSize={20} marginBottom={3}>
              {t('監視スケジュール')}
            </Typography>
            <HelpTooltip title={t('message.監視スケジュールツールチップテキスト')} />
          </div>
          <div style={{display: 'flex', gap: 16}}>
            <TextField
              label={t('期間From')}
              type="time"
              value={from}
              onChange={e => setFrom(e.target.value)}
              style={{width: '120px'}}
            />
            <TextField
              label={t('期間To')}
              type="time"
              value={to}
              onChange={e => setTo(e.target.value)}
              style={{width: '120px'}}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Typography> {t('曜日指定*')}</Typography>
              <div>
                {Object.keys(DAYS_MAP).map(dayJp => (
                  <FormControlLabel
                    key={dayJp}
                    control={
                      <Checkbox
                        checked={days.includes((DAYS_MAP as any)[dayJp])}
                        onChange={() =>
                          setDays(
                            prevDays =>
                              prevDays.includes((DAYS_MAP as any)[dayJp])
                                ? prevDays.filter(d => d !== (DAYS_MAP as any)[dayJp]) // クリックしたdayを除外
                                : [...prevDays, (DAYS_MAP as any)[dayJp]] // クリックしたdayを追加
                          )
                        }
                      />
                    }
                    label={t(`${dayJp}曜`).replace('曜', '')}
                  />
                ))}
              </div>
            </div>
          </div>

          <div style={{width: '256px'}}>
            <Typography>{t('判定頻度')}</Typography>
            <Select
              value={interval}
              onChange={e => setInterval(e.target.value)}
              style={{width: '100%'}}
            >
              <MenuItem value="1">1{t('時間毎')}</MenuItem>
              <MenuItem value="2">2{t('時間毎s')}</MenuItem>
            </Select>
          </div>
        </div>

        {/* 通知先一覧 */}
        <div
          style={{
            marginBottom: 16,
            border: '3px solid',
            width: '100%',
            borderRadius: 10,
            padding: 16
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Typography fontSize={20} marginBottom={3}>
              {t('通知先一覧')}
            </Typography>
            <HelpTooltip
              title={t(
                'message.監視判定結果の通知先を指定します。LFC登録ユーザーのメールアドレスを検索して追加ボタンを押してください。リストに加わった通知先に対して、「通知を送信する」「異常時のみ送信する」を選択可能です。'
              )}
            />
          </div>
          <div style={{display: 'flex', alignItems: 'center', gap: 16, marginBottom: 16}}>
            <Autocomplete
              options={emails.map(email => email.email)} // 候補リスト
              value={email}
              onChange={(event, newValue) => {
                newValue && setEmail(newValue);
                setEmail(newValue || '');
              }}
              inputValue={email}
              onInputChange={(event, newInputValue) => setEmail(newInputValue)}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('メールアドレス検索')}
                  variant="outlined"
                  fullWidth
                />
              )}
              style={{width: '250px'}}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddNotification}
              size="large"
              disabled={email === ''}
            >
              {t('通知先追加')}
            </Button>
          </div>

          {/* 通知先一覧テーブル */}
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t('メールアドレス')}</TableCell>
                  <TableCell>{t('ユーザー名')}</TableCell>
                  <TableCell align="center">{t('判定結果送信')}</TableCell>
                  <TableCell align="center">{t('異常時のみ結果送信')}</TableCell>
                  <TableCell align="center">{t('削除')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notifications.length === 0 ? (
                  <Typography padding={5}>{t('message.通知先がありません')}</Typography>
                ) : (
                  notifications.map((notification: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>
                        {emails.find(email => email.id === notification.email).email}
                      </TableCell>
                      <TableCell>{notification.email}</TableCell>
                      <TableCell align="center">
                        <Checkbox
                          checked={!notification.abnormalOnly}
                          onChange={() => handleCheckboxChange(index, 'abnormalonly')}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Checkbox
                          checked={notification.abnormalOnly}
                          onChange={() => handleCheckboxChange(index, 'abnormalOnly')}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => handleDeleteNotification(index)}
                        >
                          {t('削除')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        {/* Save buttons */}
        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 16}}>
          <div style={{display: 'flex', justifyContent: 'justify-start', gap: 20}}>
            <Button variant="contained" color="primary" onClick={onSubmit} disabled={disabled}>
              {t('設定の保存')}
            </Button>
            <Button variant="outlined" color="primary" onClick={onClose}>
              {t('保存せず戻る')}
            </Button>
          </div>

          <Button
            variant="contained"
            color="secondary"
            onClick={onDelete}
            disabled={alertId === '-1'}
          >
            {t('設定を削除')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
