/**
 * 共通 FontFamily
 */
export const getFontFamily = (): string => {
  return [
    // "BIZ UDPGothic",
    // "Meiryo",
    '游ゴシック Medium',
    '游ゴシック体',
    'Yu Gothic Medium',
    'YuGothic',
    'ヒラギノ角ゴ ProN',
    'Hiragino Kaku Gothic ProN',
    'Noto Sans JP',
    'Lato',
    'メイリオ',
    'Meiryo',
    'ＭＳ Ｐゴシック',
    'MS PGothic',
    'sans-serif'
  ].join(',');
};

/**
 * 共通カラー
 * @param theme
 * @returns
 */
export const getColors = (theme: 'dark' | 'light') => {
  return theme === 'dark'
    ? {
        primaryColor: 'rgb(128, 222, 234)', //cyan 200
        secondaryColor: 'rgb(255, 171, 145)', //deep orange 200
        textColor: {
          primary: 'rgb(128, 222, 234)',
          secondary: 'rgba(128, 222, 234,0.8)',
          disabled: 'rgba(128, 222, 234,0.5)',
          hint: 'rgba(128, 222, 234,0.5)'
        },
        backgroundColor: {default: 'rgb(26, 26, 26)', paper: 'rgb(26, 26, 26)'},
        backgroundColorBackdrop: 'rgba(26, 26, 26, 0.75)',
        scrollbarColor: {bar: 'rgba(128, 222, 234,1)', back: 'rgba(128, 222, 234,0.2)'},
        dividerColor: 'rgba(128, 222, 234,0.2)',
        chartsLineColor: 'rgba(128, 222, 234,0.3)',
        chartsSplitColor: 'rgba(128, 222, 234,0.1)',
        chartsLabelColor: 'rgba(0,0,0,0.8)',
        shadowColor: 'rgba(128, 222, 234, 0.7)'
      }
    : {
        primaryColor: 'rgb(33, 150, 243)', //blue 500
        secondaryColor: 'rgb(255, 87, 34)', //deep orange 500
        textColor: {
          primary: 'rgb(0,0,0)',
          secondary: 'rgba(0,0,0,0.8)',
          disabled: 'rgba(0,0,0,0.45)',
          hint: 'rgba(0,0,0,0.45)'
        },
        backgroundColor: {default: 'rgb(242, 242, 242)', paper: 'rgb(242, 242, 242)'},
        backgroundColorBackdrop: 'rgba(242, 242, 242, 0.75)',
        scrollbarColor: {bar: 'rgba(33, 150, 243,1)', back: 'rgba(33, 150, 243,0.2)'},
        dividerColor: 'rgba(0,0,0,0.2)',
        chartsLineColor: 'rgba(0,0,0,0.3)',
        chartsSplitColor: 'rgba(0,0,0,0.05)',
        chartsLabelColor: 'rgba(0,0,0,0.8)',
        shadowColor: 'rgba(0, 0, 0, 0.3)'
      };
};
