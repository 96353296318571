import LFCButton from '_components/inputs/LFCButton';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import LFCTextField from '_components/inputs/LFCTextField';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import {MenuTabContext} from '_contexts/MenuTabProvider';
import {getLFCData, handleInputChange2, setLFCData} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from 'tss-react/mui';

import {
  AppBar,
  Box,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs
} from '@mui/material';

import TabMenuList from './components/TabMenuList';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  style?: any;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <section
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </section>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles()(theme => {
  return {
    root: {
      flexGrow: 1,
      width: '100%',
      marginTop: 10
    },
    formcontrol: {
      display: 'flex'
    },
    imgStyle: {
      width: '100%',
      height: '100%'
    },
    table: {
      maxWidth: '100%'
      // Width: '50%',
      // border: '1px solid black'
    },
    table_inner: {
      maxWidth: '100%'
      // Width: '50%',
    },
    table_list: {
      '&:hover': {
        backgroundColor: 'rgba(152,151,151,0.25)'
      }
    },
    search: {
      display: 'flex',
      alignItems: 'start',
      // margin: "10px 0px 0px",
      '& > *': {
        margin: theme.spacing(1)
        // width: "20ch",
      }
    },
    mgt20_padin10_w400_center: {
      margin: 20,
      padding: 10,
      textAlign: 'center',
      width: '400px'
    },
    mgt20_padin10_w200_center: {
      margin: 20,
      padding: 10,
      textAlign: 'center',
      width: '200px'
    }
  };
});

const TabsSettingAdminPage = () => {
  const {t} = useTranslation();
  const {classes} = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const MenuTabContextData = useContext(MenuTabContext);
  const [tabList, setTabList] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  const [searchValue, setSearchValue] = useState({
    tab_id: 0,
    company_id: 0
  });

  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue);
  };

  const [left, setLeft] = useState<readonly any[]>([]);
  const [right, setRight] = useState<readonly any[]>([]);

  const [allTabList, setAllTabList] = useState<any>([]);
  const [allTabListSelect, setAllTabListSelect] = useState<any>([]);
  const [deleteTabList, setDeleteTabList] = useState([]);

  const [companyListData, setCompanyListData] = useState([]);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    setStartProcess(true);
    apiFetch().then(() => setStartProcess(false));
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const apiFetch = async () => {
    setLeft([]);
    setRight([]);

    // if (!unmount) {return}
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 90011,
      parameters: {},
      ds_state: setAllTabList,
      name: `${t('タブ管理(作成・変更)一覧取得')}`,
      cancelToken: source.token,
      t
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 90,
      parameters: {},
      ds_state: setCompanyListData,
      name: `${t('会社一覧取得')}`,
      cancelToken: source.token,
      t
    });
    setLeft([]);
    setRight([]);
    // setTabList([]);
    // setCompanyList([]);
    setSearchValue({
      tab_id: 0,
      company_id: 0
    });
  };

  useEffect(() => {
    if (searchValue.tab_id > 0) {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 90009,
        parameters: {tab_id: searchValue.tab_id},
        ds_state: setRight,
        name: `${t('タブ管理：対象のタブに入っているページリスト')}`,
        cancelToken: source.token,
        t
      });
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 90010,
        parameters: {tab_id: searchValue.tab_id},
        ds_state: setLeft,
        name: `${t('タブ管理：対象のタブに入っていないページリスト')}`,
        cancelToken: source.token,
        t
      });
    } else {
      setLeft([]);
      setRight([]);
    }
  }, [searchValue.tab_id]);

  useEffect(() => {
    setAllTabListSelect(allTabList);
    let tmp: any = [];
    if (allTabListSelect.length > 0) {
      tmp.push({name: t('タブを選択してください'), value: 0});
      allTabListSelect.forEach((item: any) => {
        tmp.push({name: item['tab_name'], value: item['tab_id']});
      });
    }
    setTabList(tmp);
  }, [allTabList]);

  useEffect(() => {
    let tmp: any = [];
    if (companyListData.length > 0) {
      tmp.push({name: t('会社を選択してください'), value: 0});
      companyListData.forEach((item: any) => {
        tmp.push({name: item['name'], value: item['id']});
      });
    }
    setCompanyList(tmp);
  }, [companyListData]);

  const thisSaveTab = async () => {
    // if (!unmount) {return}
    setStartProcess(true);
    let tmp_add_list: any = allTabList.filter((item: any) => item.tab_id > 90000000000);
    let tmp_update_list: any = allTabList.filter((item: any) => item.tab_id < 90000000000);
    if (tmp_add_list.length > 0) {
      for (const item of tmp_add_list) {
        await setLFCData({
          snack: enqueueSnackbar,
          sql_id: 90012,
          parameters: {
            tab_name: item.tab_name,
            limit: 1,
            offset: 0
          },
          name: `${t('新規追加タブの保存')}`,
          cancelToken: source.token,
          t
        });
      }
    }
    if (tmp_update_list.length > 0) {
      for (const item of tmp_update_list) {
        await setLFCData({
          snack: enqueueSnackbar,
          sql_id: 90013,
          parameters: {
            tab_id: item.tab_id,
            tab_name: item.tab_name,
            limit: 1,
            offset: 0
          },
          name: `${t('タブ名称・タブの並び順の更新')}`,
          cancelToken: source.token,
          t
        });
      }
    }
    if (deleteTabList.length > 0) {
      for (const item of deleteTabList) {
        await setLFCData({
          snack: enqueueSnackbar,
          sql_id: 90014,
          parameters: {
            tab_id: item,
            limit: 1,
            offset: 0
          },
          name: `${t('タブの削除')}`,
          cancelToken: source.token,
          t
        });
      }
    }

    await setUpdateStatus(false);
    await setAddTabStatus(false);
    await enqueueSnackbar(t('message.タブ設定を保存しました。'), {variant: 'info', persist: true});
    MenuTabContextData.reLoad();
    await setStartProcess(false);
  };

  const thisSavePageFromComponent = async (listR: any, listL: any) => {
    // if (!unmount) {return}
    setRight(listR);
    setLeft(listL);
    await setStartProcess(true);
    if (searchValue.tab_id) {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      let tmp: any = listR.map((item: any) => item['page_id']);
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 90016,
        parameters: {
          tab_id: searchValue.tab_id,
          page_ids: '{' + tmp.join(',') + '}',
          limit: 1,
          offset: 0
        },
        name: `${t('表示中のタブを保存')}`,
        cancelToken: source.token,
        t
      });
    }
    await setUpdateStatus(false);
    await setAddTabStatus(false);
    await enqueueSnackbar(t('message.タブ設定を保存しました。'), {variant: 'info', persist: true});
    MenuTabContextData.reLoad();
    await setStartProcess(false);
  };

  const [editTab, setEditTab] = useState(0);
  const [tabInput, setTabInput] = useState({
    tab_id: 0,
    tab_name: ''
  });
  const [tabInputNew, setTabInputNew] = useState({
    tab_name: ''
  });
  const [addTabStatus, setAddTabStatus] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);

  const editTabName = (tabId: number, index: number) => {
    setEditTab(tabId);
    setTabInput({tab_id: tabId, tab_name: allTabList[index]['tab_name']});
  };

  const quitEditTabName = (tabId: number, index: number) => {
    setEditTab(0);
    setAllTabList(
      allTabList.map((item: any) => {
        if (item.tab_id === tabInput.tab_id) {
          setUpdateStatus(true);
          return {
            tab_id: item.tab_id,
            tab_name: tabInput.tab_name,
            selected_page: item.selected_page,
            number_of_users: item.number_of_users
          };
        } else {
          return {
            tab_id: item.tab_id,
            tab_name: item.tab_name,
            selected_page: item.selected_page,
            number_of_users: item.number_of_users
          };
        }
      })
    );
  };

  const tabEditInput = (event: any, tab_id: number, index: number) => {
    setTabInput({tab_id: tab_id, tab_name: event.target.value});
  };

  const tabNewInput = (event: any) => {
    setTabInputNew({tab_name: event.target.value});
  };

  const quitTabNewInput = () => {
    if (tabInputNew.tab_name !== '') {
      let tmp: any = allTabList;
      tmp.push({
        tab_id: 90000000000 + allTabList.length,
        tab_name: tabInputNew.tab_name,
        selected_page: 0,
        number_of_users: 0
      });
      setAllTabList(tmp);
    }
    setTabInputNew({tab_name: ''});
    setUpdateStatus(true);
    setAddTabStatus(false);
  };

  const tabDelete = (tab_id: number) => {
    let tmp_deleteTabList: any = deleteTabList;
    tmp_deleteTabList.push(tab_id);
    setDeleteTabList(tmp_deleteTabList);
    let tmp_allTabList: any = allTabList.map((i: any) => i);
    let tmp: number = allTabList.findIndex((item: any) => item.tab_id === tab_id);
    tmp_allTabList.splice(tmp, 1);
    setAllTabList(tmp_allTabList);
    setUpdateStatus(true);
  };

  useEffect(() => {
    if (searchValue.company_id > 0) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 90017,
        parameters: {
          p_company_id: searchValue.company_id
        },
        ds_state: setRight,
        name: `${t('タブ設定：会社設定：対象会社のタブリスト取得')}`,
        cancelToken: source.token,
        t
      });
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 90018,
        parameters: {
          p_company_id: searchValue.company_id
        },
        ds_state: setLeft,
        name: `${t('タブ設定：会社設定：対象会社のタブ以外のリスト取得')}`,
        cancelToken: source.token,
        t
      });
    } else {
      setLeft([]);
      setRight([]);
    }
  }, [searchValue.company_id]);

  const thisSaveCompanyTabsFromComponent = async (listR: any, listL: any) => {
    setRight(listR);
    setLeft(listL);
    await setStartProcess(true);
    if (searchValue.company_id) {
      let tmp: any = listR.map((item: any) => item['tab_id']);
      await getLFCData({
        snack: enqueueSnackbar,
        sql_id: 90019,
        parameters: {
          p_company_id: searchValue.company_id
        },
        name: `${t('タブ設定：会社設定が存在しているか確認')}`,
        cancelToken: source.token,
        t
      }).then(async (response: any) => {
        let company_status: boolean = false;
        if (response.length > 0) {
          company_status = response[0]['company_check'];
        }

        if (company_status) {
          await setLFCData({
            snack: enqueueSnackbar,
            sql_id: 90020,
            parameters: {
              p_company_id: searchValue.company_id,
              tab_ids: '{' + tmp.join(',') + '}',
              limit: 1,
              offset: 0
            },
            name: `${t('表示中のタブを保存：更新')}`,
            cancelToken: source.token,
            t
          });
        } else {
          await setLFCData({
            snack: enqueueSnackbar,
            sql_id: 90021,
            parameters: {
              p_company_id: searchValue.company_id,
              tab_ids: '{' + tmp.join(',') + '}',
              limit: 1,
              offset: 0
            },
            name: `${t('表示中のタブを保存：新規追加')}`,
            cancelToken: source.token,
            t
          });
        }
      });
    }

    await setUpdateStatus(false);
    await setAddTabStatus(false);
    await enqueueSnackbar(t('message.タブ設定を保存しました。'), {variant: 'info', persist: true});
    MenuTabContextData.reLoad();
    await setStartProcess(false);
  };

  return (
    <GenericTemplate title={t('menu.page_name.タブ設定')}>
      <ProgressBar startProcess={startProcess} />
      <section className={classes.root}>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange}>
            <Tab label={t('Tab編集')} {...a11yProps(0)} onClick={apiFetch} />
            <Tab label={t('Tab - Page設定')} {...a11yProps(1)} onClick={apiFetch} />
            <Tab label={t('会社 - Tab設定')} {...a11yProps(2)} onClick={apiFetch} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          {/*タブ管理(作成・変更・削除)*/}
          <TableContainer
            component={Paper}
            style={{
              marginTop: 10
            }}
          >
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>{t('タブ名称')}</TableCell>
                  <TableCell align={'center'}>{t('利用社数')}</TableCell>
                  <TableCell align={'center'}>{t('割り当てページ数')}</TableCell>
                  <TableCell align={'center'}>{t('削除')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allTabList.map((item: any, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell style={{width: '300px', height: '80px'}}>
                        {editTab !== item['tab_id'] ? (
                          item['tab_name']
                        ) : (
                          <LFCTextField
                            name="tab_name"
                            label="tab_name"
                            value={tabInput['tab_name']}
                            onChange={event => tabEditInput(event, item['tab_id'], index)}
                          />
                        )}
                      </TableCell>
                      <TableCell align={'center'} style={{width: '200px', height: '80px'}}>
                        {editTab !== item['tab_id'] ? (
                          <LFCButton onClick={() => editTabName(item['tab_id'], index)}>
                            変更
                          </LFCButton>
                        ) : (
                          <LFCButton
                            color="primary"
                            onClick={() => quitEditTabName(item['tab_id'], index)}
                          >
                            {t('確定')}
                          </LFCButton>
                        )}
                      </TableCell>
                      <TableCell align={'center'}>{item['number_of_users']}</TableCell>
                      <TableCell align={'center'}>{item['selected_page']}</TableCell>
                      <TableCell align={'center'}>
                        <LFCButton
                          color="secondary"
                          onClick={() => tabDelete(item['tab_id'])}
                          disabled={item['number_of_users'] !== 0}
                        >
                          {t('削除')}
                        </LFCButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {addTabStatus ? (
                  <TableRow>
                    <TableCell style={{width: '300px', height: '80px'}}>
                      <LFCTextField
                        name="tab_name"
                        label={t('タブ名称')}
                        value={tabInputNew['tab_name']}
                        onChange={event => tabNewInput(event)}
                      />
                    </TableCell>
                    <TableCell align={'center'} style={{width: '200px', height: '80px'}}>
                      <LFCButton color="primary" onClick={() => quitTabNewInput()}>
                        {t('確定')}
                      </LFCButton>
                    </TableCell>
                    <TableCell
                      colSpan={3}
                      align={'center'}
                      style={{width: '200px', height: '80px'}}
                    ></TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell colSpan={1} style={{width: '300px', height: '80px'}}></TableCell>
                    <TableCell align={'center'} style={{width: '200px', height: '80px'}}>
                      <LFCButton color="primary" onClick={() => setAddTabStatus(true)}>
                        {t('追加')}
                      </LFCButton>
                    </TableCell>
                    <TableCell
                      colSpan={3}
                      align={'center'}
                      style={{width: '200px', height: '80px'}}
                    ></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box style={{textAlign: 'center'}}>
            <LFCButton
              color="primary"
              className={classes.mgt20_padin10_w400_center}
              onClick={() => thisSaveTab()}
              disabled={!updateStatus}
            >
              {t('タブ設定を保存')}
            </LFCButton>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={1}>
          {/*タブ管理(ページ設定)*/}
          <form className={classes.search}>
            <LFCFormRowGroup>
              {tabList.length > 0 ? (
                <LFCSelectForm
                  name={'tab_id'}
                  label={'select_tab'}
                  value={searchValue.tab_id}
                  id={'tab_id'}
                  onChange={event => {
                    handleInputChange2(event, searchValue, setSearchValue);
                  }}
                  selectItem={tabList}
                />
              ) : (
                <></>
              )}
            </LFCFormRowGroup>
          </form>

          <TabMenuList
            leftList={left}
            leftName={t('未選択ページ')}
            rightList={right}
            rightName={t('割り当てページ')}
            saveObject={thisSavePageFromComponent}
            saveTitle={t('表示中のタブを保存')}
            settingType={'page'}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          {/*タブ管理(会社設定)*/}
          <form className={classes.search}>
            <LFCFormRowGroup>
              {companyList.length > 0 ? (
                <LFCSelectForm
                  name={'company_id'}
                  label={t('対象の会社名')}
                  value={searchValue.company_id}
                  id={'company_id'}
                  onChange={event => {
                    handleInputChange2(event, searchValue, setSearchValue);
                  }}
                  selectItem={companyList}
                />
              ) : (
                <></>
              )}
            </LFCFormRowGroup>
          </form>

          <TabMenuList
            leftList={left}
            leftName={t('未選択タブ')}
            rightList={right}
            rightName={t('割り当てタブ')}
            saveObject={thisSaveCompanyTabsFromComponent}
            saveTitle={t('表示中の会社のタブ設定を保存')}
            settingType={'tab'}
          />
        </TabPanel>
      </section>
    </GenericTemplate>
  );
};

export default TabsSettingAdminPage;
