import { forwardRef, RefObject, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { Box, InputBaseComponentProps, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

/**
 * Styles
 */
const useStyles = makeStyles(() => ({
  input: {
    '&:-webkit-autofill': {
      transition: 'background-color 5000s',
      boxShadow: '0 0 0 1000px transparent inset !important'
    }
  }
}));

// カスタムref用のインターフェース定義
export interface TextFieldRef {
  focus: () => void;
}

/**
 * 引数
 */
interface Props {
  name: string;
  label: string;
  value: any;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement> | undefined) => void;
  style?: any;
  other?: any;
  multiline?: boolean;
  rows?: number;
  fullWidth?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  required?: boolean;
  size?: 'small' | 'medium';
  inputProps?: InputBaseComponentProps;
  readonly?: boolean;
  sx?: {};
}

/**
 * LFCTextField
 */
const LFCTextField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const classes = useStyles();
  const myRef = useRef<HTMLInputElement>(null!);
  const [err, setErr] = useState(false);

  // useImperativeHandleを使用せず、直接myRefを転送
  useEffect(() => {
    if (ref && typeof ref === 'object') {
      (ref as React.MutableRefObject<HTMLInputElement | null>).current = myRef.current;
    }
  }, [ref]);

  /**
   * Text入力時
   * @param event
   */
  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    setErr(!event?.target.checkValidity());

    if (props.onChange) {
      props.onChange(event);
    }
  };
  /**
   * フォーカスアウト時
   * @param event
   */
  const handleFocusOut = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    setErr(!event?.target?.checkValidity());

    if (props.onBlur) {
      props.onBlur(event);
    }
  };
  /**
   * キーダウン時
   * @param event
   */
  const handeKeyDown = (event: React.KeyboardEvent<HTMLDivElement> | undefined) => {
    if (props.onKeyDown) {
      props.onKeyDown(event);
    }
  };

  return (
    <Box style={props.style}>
      <TextField
        type="text"
        name={props.name}
        label={props.label}
        value={props.value}
        inputRef={myRef}
        error={err}
        helperText={err && myRef.current?.validationMessage}
        onChange={handleInputChange}
        onBlur={handleFocusOut}
        onKeyDown={handeKeyDown}
        variant="outlined"
        InputLabelProps={{
          shrink: true
        }}
        size={props.size !== undefined ? props.size : 'small'}
        multiline={props.multiline}
        minRows={props.rows}
        maxRows={props.rows}
        fullWidth={props.fullWidth !== undefined ? props.fullWidth : false}
        disabled={props.disabled !== undefined ? props.disabled : false}
        autoFocus={props.autoFocus !== undefined ? props.autoFocus : false}
        required={props.required !== undefined ? props.required : false}
        margin={props.margin}
        InputProps={{
          classes: {input: classes.input},
          readOnly: props.readonly
        }}
        inputProps={props.inputProps !== undefined ? props.inputProps : {}}
        {...props.other}
        sx={props.sx}
      />
    </Box>
  );
});
export default LFCTextField;
