import Decimal from 'decimal.js-light';
import { useEffect, useState } from 'react';

import { DndContext, useDroppable } from '@dnd-kit/core';
import { Box } from '@mui/material';

import DraggableIcon from './DraggableIcon';

/***
 * iconのidのフォーマット関数
 */
export const formatIconId = (imageNo: number, map: string) => {
  return `${imageNo}-${map}`;
};

/***
 * iconのidのデコード関数
 */
export const decodeIconId = (id: string) => {
  const parts = id.split('-');
  const dec = [parts[0], parts.slice(1).join('-')];
  return {imageNo: Number(dec[0]), map: dec[1]};
};

export type MapIconProps = {
  id: string;
  map: string;
  color: string;
  position: {relativeX: number; relativeY: number};
  selected: boolean;
  isShowLabel: boolean; //部位名表示フラグ
  isAttention: boolean; //部位強点滅フラグ
};

type MapIconViewerProps = {
  containerWidth: number; // コンテナの幅
  containerHeight: number; // コンテナの高さ
  imgPath: string; // 背景画像パス
  icons: MapIconProps[]; // アイコン情報
  iconRatio: number; // アイコン拡大率
  onIconClick: (iconId: string, imageNo: number, map: string) => void; // アイコンクリック時の処理
};

const MapIconViewer = (props: MapIconViewerProps) => {
  const {setNodeRef: setBackgroundRef} = useDroppable({id: 'background'});
  const iconSize = props.containerWidth * (props.iconRatio / 50);
  const [relativeIcons, setRelativeIcons] = useState<MapIconProps[]>(props.icons);

  /**
   * 相対座標→絶対座標変換
   * @param relativeX
   * @param relativeY
   * @returns
   */
  const convAbsolutePos = (relativeX: number, relativeY: number) => {
    return {
      x: new Decimal(relativeX).div(100).mul(props.containerWidth).toNumber(),
      y: new Decimal(relativeY).div(100).mul(props.containerHeight).toNumber()
    };
  };

  /**
   * アイコンクリック時の処理
   * @param id
   */
  const handleIconClick = (id: string) => {
    const dec = decodeIconId(id);
    props.onIconClick(id, dec.imageNo, dec.map);
  };

  /**
   * 外部 iconデータ変更検知
   */
  useEffect(() => {
    setRelativeIcons(props.icons);
  }, [props.icons]);

  return (
    <Box>
      <DndContext>
        {/* メインコンテナ */}
        <div
          style={{
            position: 'relative',
            width: `${props.containerWidth}px `,
            height: `${props.containerHeight}px`,
            border: '3px solid gray'
          }}
        >
          {/* 背景画像エリア */}
          <div
            ref={setBackgroundRef}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundImage: `url(${props.imgPath}`,
              backgroundSize: `${props.containerWidth}px ${props.containerHeight}px`,
              backgroundPosition: 'top left',
              backgroundRepeat: 'no-repeat',
              overflow: 'visible'
            }}
          />
          {/* アイコン */}
          {relativeIcons.map(icon => {
            return (
              <DraggableIcon
                key={icon.id}
                id={icon.id}
                label={icon.map}
                color={icon.color}
                iconSize={iconSize}
                position={(() => {
                  const res = convAbsolutePos(icon.position.relativeX, icon.position.relativeY);
                  return {absoluteX: res.x, absoluteY: res.y};
                })()}
                selected={icon.selected}
                isShowLabel={icon.isShowLabel}
                isAttention={icon.isAttention}
                onClick={handleIconClick}
              />
            );
          })}
        </div>
      </DndContext>
    </Box>
  );
};

export default MapIconViewer;
